.block.cta-block {
  margin-bottom: 1em;
  .cta-block-wrapper {
    .cta-tile-text {
      p {
        font-size: 1.2rem;
      }
    }
  }
}
