.card-with-modal-video a:hover .overlay-card {
  display: flex;
  position: absolute;
  background: #2d2929bf;
  top: 0;
  color: white;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.card-with-modal-video a .overlay-card {
  display: none;
}

.icon-media {
  position: absolute;
  background: #fff;
  right: 24px;
  top: 24px;
  height: 56px;
  border-radius: 5px;
  width: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
}
