.pubblicazioni-search {
  .search-container {
    .btn.btn-link {
      color: #fff;

      &:hover,
      &:active,
      &:focus,
      &:focus-visible {
        text-decoration: underline;
        background-color: transparent;
      }
    }
    .btn:focus-visible {
      background-color: #fff;
    }
  }
  .filter-wrapper {
    flex: 1 1 auto;

    .DateRangePicker,
    .DateRangePickerInput {
      width: 100%;
      .DateInput {
        width: calc(50% - 12px);
      }
    }

    .DateInput {
      input {
        text-align: center;
      }
    }
  }

  .card {
    .card-title {
      margin-bottom: 10px;

      a {
        @include rem-size(font-size, 24);
        @include rem-size(line-height, 26);
        font-weight: 700;

        &:hover,
        &:active {
          text-decoration: underline;
        }
      }
    }
  }

  .row-3 {
    .filter-wrapper {
      flex: 1 1 32%;
    }
  }

  @media (max-width: #{map-get($grid-breakpoints, lg)}) {
    .search-container {
      > .flex-wrap {
        flex-direction: column;
        .filter-wrapper {
          width: 100%;
        }
      }
    }

    .row-3 {
      .filter-wrapper {
        flex: 1 1 100%;
      }
    }
  }
}
