.modal
  .modal-dialog.gallery-preview
  .modal-content
  .item-preview.audio-preview {
  min-height: 0;
  flex-direction: column;
}

.modal
  .modal-dialog.gallery-preview
  .modal-content
  .item-preview.video-preview {
  .block.video {
    width: 100%;
    max-height: 100%;
    min-height: 100%;
    margin-bottom: 0;
    display: grid;

    .video-inner {
      min-height: 100%;
      max-height: 100%;
      .ui.embed {
        aspect-ratio: 16/9;
        max-height: 100%;
        width: unset;
        height: auto;
        margin-left: auto;
        margin-right: auto;
        background: unset;
        padding-bottom: unset;

        .embed {
          position: relative;
          height: 100%;
        }

        img.placeholder {
          cursor: pointer;
        }

        iframe {
          width: auto;
          aspect-ratio: 16/9;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }
}

.modal
  .modal-dialog.gallery-preview.wildcard-media-gallery
  .modal-content
  .modal-body {
  display: block;
  overflow: scroll;
}
