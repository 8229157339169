.it-footer-main,
.it-footer-small-prints {
  background-color: $primary !important;
}

.it-footer-small-prints .it-footer-small-prints-list {
  border-top: 1px solid hsla(0, 0%, 100%, 0.5);
}

.it-footer .it-footer-main .it-brand-wrapper a .icon {
  width: auto;
}
