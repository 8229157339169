.navbar {
  .navbar-collapsable .menu-wrapper .it-brand-wrapper {
    background-color: $primary;
  }
  @media (max-width: #{map-get($grid-breakpoints, 'lg') - 1px}) {
    .navbar-collapsable {
      .menu-wrapper {
        .it-brand-wrapper {
          .it-brand-text {
            line-height: 1.3;
          }
          .it-brand-title,
          .it-brand-tagline {
            color: $primary-text;
          }
          .it-brand-tagline {
            font-weight: normal;
            font-size: 0.889em;
          }
        }
      }
    }
  }
}
