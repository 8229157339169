.public-ui {
  a:focus:not(.focus--mouse) {
    display: inline-block; //per avere il bordo tutto unito al focus di un link, ad esempio nelle card
  }
  li a {
    display: inline;
  }
}

.btn.inline-link {
  margin: 0.2rem;
}

.block.listing .card-with-image-template .listing-item,
.block.listing .card-with-image-template .listing-item .card-title,
.block.listing .in-evidence .listing-item,
.block.listing .in-evidence .listing-item .card-title,
.card.card-teaser.simple-card-default-item .card-body .card-title,
.card.card-teaser.simple-card-default-item .card-body,
.card.border-left-card .card-title,
.card-big-io-comune .card-body .card-title,
.card-small .card-body h5.card-title,
#documenti-collegati .genericcard .card-body h5.card-title,
.simple-card-compact-template .card-teaser-wrapper .card-title,
.contentInEvidenceTemplate .content-in-evidence .card-title,
.block.highlitedContent .card-title,
.ribbon-card-template .card h3.card-title {
  a {
    display: inline;

    &:focus {
      display: inline-block;
    }
  }
}

.repeatableContentBlock {
  .repeatable-block-image.full-width {
    a {
      display: block;
    }
    img {
      width: 100%;
    }
  }
}

button.btn.immersive-reader-launch-button {
  text-decoration: underline;
}

.export-spinner {
  float: left;
  margin-right: 8px;
  .progress-spinner.size-sm {
    width: 24px;
    height: 24px;
  }
}
