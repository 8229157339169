.bando-view {
  #tempi_e_scadenze {
    .point-list {
      .card-teaser {
        .card-body {
          .card-title {
            font-weight: 700;
          }
        }

        &.card {
          padding: 0.8em;
        }
      }
    }
  }

  #allegati {
    .card-text {
      display: flex;
      align-items: center;

      svg {
        margin-right: 0.5em;
      }

      a {
        border-bottom: none;
        color: $neutral-2-a6;
        font-size: 18px;
        text-decoration: none;
      }
    }

    .download-formats {
      display: flex;
      flex-direction: column;
    }
  }

  #text-body,
  .it-page-section {
    h5 {
      margin-top: 0.7em;
    }
  }

  .bando_state {
    width: 20em;
    border-left: 0.3em solid;
    margin-bottom: 1em;
    text-transform: uppercase;

    &.open {
      border-left: 5px solid $complementary-3-a9;

      svg {
        padding-right: 10px;
        fill: $complementary-3-a9;
      }
    }

    &.closed {
      border-left: 5px solid $neutral-1-a7;

      svg {
        padding-right: 10px;
        fill: $neutral-1-a7;
      }
    }

    &.scheduled {
      border-left: 5px solid $analogue-1-b5;

      svg {
        padding-right: 10px;
        fill: $analogue-1-b5;
      }
    }

    &.inProgress {
      border-left: 5px solid $analogue-1-a7;

      svg {
        padding-right: 10px;
        fill: $analogue-1-a7;
      }
    }
  }
}
