html,
body {
  font-size: 16px;
  line-height: 1.5;
  @include media-breakpoint-up(sm) {
    font-size: 18px;
    line-height: 1.555;
  }
}

// Intestazioni

h1,
.h1 {
  font-size: 2.5rem;
  letter-spacing: -1px;
  @include media-breakpoint-up(sm) {
    font-size: 2.666rem;
    letter-spacing: -1.2px;
    line-height: 1.25;
  }
}

h2,
.h2 {
  font-size: 2rem;
  line-height: 1.25;
  letter-spacing: normal;
  @include media-breakpoint-up(sm) {
    font-size: 2.222rem;
    line-height: 1.2;
  }
}

h3,
.h3 {
  font-size: 1.75rem;
  line-height: 1.1428;
  letter-spacing: normal;
  @include media-breakpoint-up(sm) {
    font-size: 1.777rem;
    line-height: 1.25;
  }
}

h4,
.h4 {
  font-size: 1.5rem;
  line-height: 1.1666;
  letter-spacing: normal;
  @include media-breakpoint-up(sm) {
    font-size: 1.555rem;
    line-height: 1.428;
    font-weight: 600;
  }
}

h5,
.h5 {
  font-size: 1.25rem;
  font-weight: 400;
  letter-spacing: normal;
  @include media-breakpoint-up(sm) {
    font-size: 1.333rem;
  }
}

h6,
.h6 {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: normal;
}

// Intestazioni in evidenza

.display-1,
.display-2,
.display-3,
.display-5 {
  font-weight: 700;
}

.display-4,
.display-6 {
  font-weight: 600;
}

.display-1 {
  font-size: 3.111rem;
  @include media-breakpoint-up(sm) {
    font-size: 3.111rem;
    line-height: 1.428;
  }
}

blockquote,
.blockquote {
  font-size: inherit;
  margin: 1.5rem 0;
  margin-left: calc($spacer / 2);
  border-left: 4px solid $analogue-2;
  padding: calc($spacer / 4) $spacer;
  &.text-end {
    margin-right: calc($spacer / 2);
    border-right: 4px solid $analogue-2;
  }
  &.text-center,
  &.text-end,
  &.blockquote-simple {
    margin-left: 0;
    border-left: none;
    border-right: none;
  }
  &.text-center,
  &.blockquote-simple {
    padding: 0;
  }
  &.blockquote-simple {
    font-style: italic;
  }

  //card version
  &.blockquote-card {
    margin-left: 0;
    padding: 16px 40px 24px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    .blockquote-footer {
      font-size: inherit;
      &:before {
        content: none;
      }
    }
    &.dark {
      background-color: $primary;
      border-left: none;
      color: $white;
      padding: 40px;
      .blockquote-footer {
        color: $white;
      }
    }
  }
}

caption {
  font-size: 0.777rem;
  line-height: 1.4285;
  color: $color-text-muted;
}

b,
strong {
  font-weight: 600;
}

small,
.small {
  font-size: 0.9375rem;
  font-weight: $font-weight-normal;
  @include media-breakpoint-up(sm) {
    font-size: 0.8888rem;
  }
}

.x-small {
  font-size: 0.875rem;
  font-weight: $font-weight-normal;
  @include media-breakpoint-up(sm) {
    font-size: 0.7777rem;
  }
}

.blockquote-footer {
  color: $blockquote-small-color;
  margin-top: 0;
  .bg-dark & {
    color: $blockquote-small-color-dark;
  }
}

// Font Family
.font-serif {
  font-family: $font-family-serif !important;
}
.font-sans-serif {
  font-family: $font-family-sans-serif !important;
}
.font-monospace {
  font-family: $font-family-monospace !important;
}

// Abbreviazioni
.initialism {
  font-size: 90%;
}

a {
  &.text-decoration-none {
    &:hover {
      text-decoration: underline !important;
    }
  }
}
