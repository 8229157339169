.figure {
  &.img-full {
    img {
      width: 100%;
      height: 100%;
      max-height: 600px;
      object-fit: cover;
    }
  }
}
