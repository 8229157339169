.search-container {
  input[type='text'] {
    height: 2.75rem;
    font-family: var(--font-family);
    color: #484848;

    &::placeholder {
      font-weight: normal;
      color: #435a70 !important;
      font-family: var(--font-family);
      font-weight: 300 !important;
    }
  }
  .react-select__single-value {
    color: #484848 !important;
  }

  .DateInput {
    input {
      border-bottom: none;

      &::placeholder {
        font-weight: normal;
      }
    }
  }
}
