.contentInEvidenceTemplate {
  .content-in-evidence {
    .item-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .card-title {
      a {
        display: inline-block; //per avere il bordo tutto unito al focus di un link, ad esempio nelle card
        font-size: 1.7rem;
        line-height: 2.3rem;
        text-decoration: none;
        transition: 0.2s color ease;

        &:hover,
        &:focus {
          color: $link-color;
          text-decoration: underline;
        }
      }
    }

    .event-recurrences-more {
      position: unset;
      bottom: 0;
      margin-top: 2.5em;
    }
  }

  .content-in-evidence + .content-in-evidence {
    padding: 1em 0;
    margin-top: 1em;
  }

  .content-in-evidence:nth-of-type(2n) {
    background-color: $lightgrey-c2;

    .card {
      background: transparent;
    }
  }

  @media (min-width: #{map-get($grid-breakpoints, lg)}) {
    .content-in-evidence:nth-of-type(2n) {
      .order-lg-2 {
        order: 1 !important;

        &.offset-lg-1 {
          margin-left: 0;
        }
      }

      .order-lg-1 {
        order: 2;
      }
    }
  }

  .skeleton-template {
    .item-image {
      @extend %skeleton-animation;
      min-height: 2em;
      background-color: $skeleton-bg;
    }
  }
}
