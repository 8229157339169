/* only edit information */
.cms-ui .ui.image .info-device {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 1rem;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  background: white;
  font-size: 0.9rem;

  svg {
    display: inline-block;
    margin-right: 0.5rem;
  }
}

.block.image {
  &.align.left,
  &.align.right {
    .info-device {
      clear: both;
      margin-top: -3rem;
      margin-bottom: 1rem;
    }

    img.large + .info-device,
    img + .info-device {
      width: 50%;
    }

    img.medium + .info-device {
      width: 25%;
    }

    img.small + .info-device {
      width: 15%;

      .text {
        display: none;
      }
    }
  }
  &.align.left .info-device {
    float: left;
  }
  &.align.right .info-device {
    float: right;
  }

  img {
    height: auto;
  }

  img.full-width.h-auto {
    height: auto !important;
  }
  a {
    display: inline;
  }
}

.public-ui .block.image {
  &.mobile {
    @media (min-width: #{map-get($grid-breakpoints, sm)}) {
      display: none;
    }
  }

  &.desktop {
    @media (max-width: #{map-get($grid-breakpoints, sm)}) {
      display: none;
    }
  }
}
