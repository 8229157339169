.toolbar {
  width: 100%;
  height: 96px;
  position: relative;

  a {
    text-decoration: none;
  }
  // toolbar vertical ----------------- START
  &.toolbar-vertical {
    height: 100%;
    width: 96px;
    position: absolute;
    top: 0;
    bottom: 0;
    & > ul {
      height: 100%;
      flex-direction: column;
      width: 64px;
      & > li {
        margin: 8px 0;
        display: flex;
        align-items: center;
        // divider
        &.toolbar-divider {
          margin: 0;
          height: 1px;
          min-height: 1px; // fix for IE11
          max-height: 1px;
          width: calc(100% - 16px);
          max-width: inherit;
        }
      }
    }

    &.toolbar-medium,
    &.toolbar-small {
      height: 100%;
      & > ul {
        height: 100%;
      }
    }

    &.toolbar-medium {
      width: 84px;
      & > ul {
        width: 48px;
      }

      // badge
      .toolbar-badge {
        right: -4px;
      }

      // dropdown
      .btn-dropdown {
        &:after {
          right: -10px;
          top: 13px;
          font-size: 0.39rem;
        }
      }
      .dropdown-menu {
        top: calc(-100% - 25px) !important;
      }
    }

    &.toolbar-small {
      width: 72px;
      & > ul {
        width: 40px;
      }

      // badge
      .toolbar-badge {
        right: -4px;
      }

      // dropdown
      .btn-dropdown {
        &:after {
          right: -9px;
          top: 11px;
          font-size: 0.33rem;
        }
      }
      .dropdown-menu {
        top: calc(-100% - 35px) !important;
      }
    }

    // dropdown
    .btn-dropdown {
      &:after {
        right: -12px;
        transform: rotate(-90deg);
      }
      &[aria-expanded='true'] {
        &:after {
          transform: rotate(90deg) scaleX(-1);
        }
      }
    }
    .dropdown-menu {
      top: calc(-100% - 28px) !important;
      left: calc(100% + 24px) !important;
      margin: 0;
      box-shadow: $dropdown-box-shadow-vertical;
      &:before {
        left: -6px;
        top: 24px;
      }
    }

    // shadow
    &:before {
      width: 360px;
      height: 100%;
      left: auto;
      top: 0;
      bottom: auto;
      right: 32px;
      z-index: 6;
      background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 60%);
      border-radius: 50%;
      transform: translateX(50%) scalex(0.12) scaleY(1.25);
      opacity: 0.18;
    }

    .toolbar-badge {
      right: -10px;
    }
  }
  // toolbar vertical ----------------- END

  &.toolbar-medium {
    height: 80px;
    //  list
    & > ul {
      height: 48px;
    }
    a,
    .btn-dropdown {
      margin-bottom: 6px;
    }
    .btn-dropdown {
      // dropdow caret
      &:after {
        top: 12px;
        right: 1px;
      }
    }
    // icon
    .it-ico {
      font-size: 1.35rem;
      // more icon
      &.it-more-actions {
        &:before {
          top: 33%;
        }
      }
    }
    // icon badge
    .toolbar-badge {
      height: 10px;
      width: 10px;
      min-width: 10px;
      right: 8px;
      top: 4px;
      padding: 0;
    }
  }

  &.toolbar-small {
    height: 72px;
    //  list
    & > ul {
      height: 40px;
    }
    .btn-dropdown {
      // dropdow caret
      &:after {
        top: 12px;
        right: 2px;
      }
    }
    // icon
    .it-ico {
      font-size: 1.15rem;
      &.it-more-actions {
        &:before {
          top: 25%;
        }
      }
    }
    // icon badge
    .toolbar-badge {
      height: 8px;
      width: 8px;
      min-width: 8px;
      right: 10px;
      top: 2px;
      padding: 0;
    }
  }

  // shadow
  &:before {
    content: '';
    position: absolute;
    height: 360px;
    width: 100%;
    top: auto;
    bottom: 32px;
    z-index: 6;
    background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 60%);
    border-radius: 50%;
    transform: translateY(50%) scalex(1.4) scaleY(0.12);
    opacity: 0.18;
  }

  //  list
  & > ul {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 7;
    background-color: $white;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0;
    margin: 0;
    height: 64px;
    & > li {
      list-style-type: none;
      margin: 0 8px;
      text-align: center;
      flex: 1 100%;
      // divider
      &.toolbar-divider {
        margin: 0;
        width: 1px;
        min-width: 1px; // fix for IE11
        max-width: 1px;
        height: calc(100% - 16px);
        background: $gray-border;
      }
    }
  }
  // link and button
  a,
  .btn-dropdown {
    display: block;
    color: $color-text-secondary;
    .icon {
      fill: $secondary;
    }
    // active
    &.active {
      color: $primary;
      .icon {
        fill: $primary;
      }
    }
    // disabled
    &.disabled {
      cursor: not-allowed;
      color: $gray-disabled;
      .icon {
        fill: $gray-disabled;
      }
      &:hover {
        text-decoration: none;
        color: $gray-disabled;
        .icon {
          fill: $gray-disabled;
        }
      }
      &:after {
        color: $gray-disabled;
        .icon {
          fill: $gray-disabled;
        }
      }
    }
    // hover
    &:not(.disabled):hover {
      text-decoration: underline;
      color: $color-text-primary-hover;
      .icon {
        fill: $color-text-primary-hover;
      }
    }
    // hover fix for ie11
    /* stylelint-disable */
    _:-ms-fullscreen,
    &:not(.disabled):hover {
      text-decoration: none;
    }
    /* stylelint-enable */
  }

  // dropdown button
  .btn-dropdown {
    padding: 0;
    width: 100%;
    position: relative;
    &.toolbar-more {
      &:after {
        display: none;
      }
    }
    &:not(.disabled) {
      &.active {
        box-shadow: none;
        &:focus {
          box-shadow: $btn-focus-box-shadow;
        }
      }
    }

    &:focus {
      box-shadow: $btn-focus-box-shadow;
    }

    // dropdown caret
    &:after {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 4px 3px 0 3px;
      border-color: $secondary transparent transparent transparent;
      position: absolute;
      top: 16px;
      right: 2px;
      margin-left: 0;
    }
  }

  // icon
  .it-ico {
    position: relative;
    font-size: 1.6rem;
    display: block;
    height: 32px;
    &:before {
      margin-right: 0;
    }
    // more icon
    &.it-more-actions {
      font-size: 0.6rem;
      &:before {
        position: relative;
        top: 50%;
      }
    }
  }

  // icon badges ------------------
  .badge-wrapper {
    position: relative;
  }

  .toolbar-badge,
  .toolbar-alert {
    display: block;
    position: absolute;
    background-color: $primary;
    border: 1px solid $white;
  }

  // icon badge
  .toolbar-badge {
    top: 0;
    right: 6px;
    min-width: 18px;
    height: 18px;
    padding: 0 4px;
    color: $white;
    font-size: 0.563rem;
    border-radius: 50%;
    font-style: normal;
  }

  // text label
  .toolbar-label {
    margin-top: 8px;
    font-size: 0.688rem;
    line-height: 1;
    display: block;
    font-weight: 600;
  }
}
