.subsite-social-links-widget {
  .social-list {
    border-bottom: 1px solid #eee;
    margin-bottom: 1rem !important;

    .icon {
      width: 30px !important;
    }
  }
}
