//site
$primary-h: 211 !default;
$primary-s: 77 !default;
$primary-b: 37 !default;
// $primary: #16395f;

//$secondary;
$tertiary: #3e6e94;
//$tertiary-text: white;

$header-slim-bg-color: #0f2a48;
$focus-on-primary: #ffbb57;

//$headings-color: $primary;
//$link-color: hsl(210deg, 100%, 40%);
$font-family-serif: 'Titillium Web', Geneva, Tahoma, sans-serif;

$alert-danger: #16395e; //primary
$alert-danger-text: #fff;
$alert-warning: #5d7083; //secondary
$alert-warning-text: #fff;
$alert-info: #eee;
$alert-info-text: #000;

// placeholder field color text for white and gray backgroud-color
$input-placeholder-color: #595959;
