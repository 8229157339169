.canale-digitale-widget {
  .introduction-helper-wrapper {
    padding: 1rem 1rem 0 1rem;
  }

  .field-title::after {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-left: 5px;
    background-color: #e40166;
    content: '';
  }
}
