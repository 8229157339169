.contenttype-wildcardvideo {
  .block.video {
    .video-inner {
      .ui.embed {
        width: 660px;
        height: auto;
        aspect-ratio: 16/9;

        background: unset;
        padding-bottom: unset;

        img.placeholder {
        }
      }
    }
  }
}
