.block.calendar,
.calendar {
  .calendar-body {
    .calendar-item {
      .item-info div:first-child {
        display: none;
      }
    }
  }
}
