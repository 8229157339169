.block.listing .card-with-image-template {
  .col-item {
    margin-bottom: 22px;
  }

  .icon {
    width: 32px;
    height: 32px;
  }

  .listing-item {
    a {
      display: inline-block;
    }

    .card-with-image-additional-links a {
      width: auto;
    }

    .img-responsive-wrapper {
      width: inherit;

      .img-responsive {
        .img-wrapper {
          display: flex;
          align-items: center;

          picture {
            display: flex;
            width: 100%;
            height: 100%;
          }

          img {
            margin-right: 0;
          }
        }
      }

      &.natural-image-size {
        .img-responsive {
          position: static;
          height: auto;
          padding: 0;

          .img-wrapper {
            position: static;
            margin: 0;
          }
        }
      }
    }

    .img-link {
      &:after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: transparentize($color: white, $amount: 1);
        content: '';
        transition: 0.2s background-color ease;
      }

      &:hover,
      &:focus {
        &:after {
          background-color: transparentize($color: white, $amount: 0.9);
        }
      }
    }

    .card-title {
      a {
        display: inline-block; //per avere il bordo tutto unito al focus di un link, ad esempio nelle card
        width: initial;
        color: $body-color;
        text-decoration: none;
        transition: 0.2s color ease;

        &:hover,
        &:focus {
          color: $link-color;
          text-decoration: underline;
        }
      }
    }

    .event-recurrences-more {
      bottom: $v-gap * 3;
    }
  }
}

.card-with-image-default-item.listing-item.card {
  .card-body {
    .category-top {
      color: $caption-text;
      font-size: 0.875rem;
      line-height: 1.313rem;

      .text {
        font-weight: 600;
        letter-spacing: 1px;
        color: $caption-text;
      }

      .data {
        font-weight: 400;
        letter-spacing: 1.1px;
      }
    }

    .card-title {
      a {
        color: $link-color;
        font-size: 1.3rem;
        font-weight: 700;
        line-height: 1.75rem;
      }
    }

    .card-text {
      color: $gray-secondary;
      font-family: $font-family-sans-serif;
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }

  .icon {
    width: 32px;
    height: 32px;
  }

  .img-responsive-wrapper {
    width: inherit;

    .img-responsive {
      .img-wrapper {
        display: flex;
        align-items: center;

        picture {
          display: flex;
          width: 100%;
          height: 100%;
        }

        img {
          margin-right: 0;
        }
      }
    }

    &.natural-image-size {
      .img-responsive {
        position: static;
        height: auto;
        padding: 0;

        .img-wrapper {
          position: static;
          margin: 0;
        }
      }
    }
  }

  @include media-breakpoint-only(lg) {
    .listing-item.card {
      .card-body {
        .category-top {
          flex-wrap: wrap;
        }

        .data.data {
          flex-basis: 100%;
          margin-top: 3px;

          &:before {
            display: none;
          }
        }
      }
    }
  }
  @include media-breakpoint-down(sm) {
    .listing-item.card {
      .card-body {
        .category-top {
          flex-wrap: wrap;
        }

        .data.data {
          flex-basis: 100%;
          margin-top: 3px;

          &:before {
            display: none;
          }
        }
      }
    }
  }
}
