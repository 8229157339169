.it-footer-small-prints {
  li {
    color: #fff;
    font-size: 15px;
  }
}

.richtext-blocks.footerTop {
  width: 100%;
  a {
    text-decoration: underline;
  }
}

.public-ui .it-footer-main .it-brand-wrapper .richtext-blocks.footerTop a {
  text-decoration: underline;
}
