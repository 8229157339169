html,
body.public-ui {
  font-size: 16px;
  line-height: 1.5;

  @include media-breakpoint-up(sm) {
    font-size: 18px;
    line-height: 1.555;
  }
}
