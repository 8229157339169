.embedded-video {
  .ui.embed {
    position: relative;
    width: 100%;
    max-width: unset;
    height: auto;
    padding: 0 1em;
    background: transparent;

    > .embed {
      iframe {
        position: relative;
        height: 350px;
      }
    }

    img.placeholder {
      width: 100%;
      height: 350px;
      object-fit: cover;
    }

    @media (max-width: #{map-get($grid-breakpoints, md)}) {
      > .embed {
        iframe {
          height: 250px;
        }
      }

      img.placeholder {
        height: 250px;
      }
    }

    .icon-play {
      position: absolute;
      z-index: 2;

      top: 50%;
      left: 50%;
      width: 3.6rem;
      height: 2.5rem;
      border-radius: 15px;
      background-color: rgba(0, 0, 0, 0.7);

      color: $white;
      cursor: pointer;
      line-height: 2.5rem;
      text-align: center;
      transform: translateX(-50%) translateY(-50%);

      svg {
        width: 1rem;
        height: 1rem;
      }

      &:hover,
      &:active,
      &:focus {
        background-color: $primary;
        color: $primary-text;
      }
    }

    &.active {
      .icon-play,
      img.placeholder {
        display: none;
      }
    }
  }
}
