@media (min-width: #{map-get($grid-breakpoints, lg)}) {
  .it-header-wrapper {
    .it-nav-wrapper {
      .it-header-navbar-wrapper {
        nav {
          border-top: 1px solid hsla(0, 0%, 100%, 0.5);
        }
      }
    }
  }
}
