.block.contacts {
  .full-width {
    height: auto;
  }

  @include blocks-with-bg-edit-buttons();

  .bg-primary {
    .block-header {
      .title,
      .description,
      .description h1,
      .description h2,
      .description h3,
      .description h4,
      .description h5,
      .description h6,
      .description a:not(.btn) {
        color: $primary-text;
      }
    }
  }

  .bg-secondary {
    .block-header {
      .title,
      .description,
      .description h1,
      .description h2,
      .description h3,
      .description h4,
      .description h5,
      .description h6,
      .description a:not(.btn) {
        color: $secondary-text;
      }
    }
  }

  .block-header {
    .title {
      margin: 1rem 0 2rem 0;
      font-size: 2.2rem;
      font-weight: bold;
      line-height: 3rem;
      text-align: center;
    }

    .description {
      margin-bottom: 2rem;

      line-height: 1.65em;
      text-align: center;
    }
  }

  .col-md-4 {
    margin-bottom: 1rem;
  }

  .single-block.subblock-edit {
    height: 100%;

    .card-wrapper {
      height: 100%;
    }
  }

  .button.add-element {
    margin-top: 1em;
  }

  .card {
    &:after {
      content: none;
    }

    .contact-title {
      margin-bottom: 1rem;
      font-size: 1.35rem;
      font-weight: bold;
      line-height: 1.4em;
    }

    .contact-text {
      font-size: 0.9rem;

      .detached-slate-editor {
        margin-bottom: 1rem;
      }
    }

    .contact-info {
      display: flex;
      align-items: center;

      [role='textbox'] {
        //slate editor
        width: 100%;

        &[data-slate-editor='true'] {
          min-height: 1em;
        }
      }

      .icon-wrapper {
        display: flex;
        align-items: center;
        margin-top: 0.2rem;
        margin-right: 1rem;
      }

      .tel {
        font-size: 1.8rem;
      }

      .email {
        font-size: 1.26rem;
      }

      .tel,
      .email {
        flex: 1 1 auto;
        word-break: break-word;

        p {
          line-height: 1.48em;

          &:last-child {
            margin: 0;
          }
        }
      }
    }
  }

  @media (max-width: #{map-get($grid-breakpoints, lg)}) {
    .card {
      .contact-info {
        .icon {
          width: 20px;
          height: 20px;
        }

        .tel {
          font-size: 1.5rem;
          word-break: break-word;
        }

        .email {
          font-size: 1rem;
        }
      }
    }
  }
}
