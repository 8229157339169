.bottom-nav {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  height: 96px;
  // shadow
  &:before {
    content: '';
    position: absolute;
    height: 360px;
    width: 100%;
    top: 32px;
    z-index: 10;
    background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0) 60%);
    border-radius: 50%;
    transform: translateY(-50%) scalex(1.4) scaleY(0.12);
    opacity: 0.18;
  }

  //  list
  ul {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 20;
    background-color: $white;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0;
    margin: 0;
    height: 64px;
    li {
      list-style-type: none;
      margin: 8px;
      text-align: center;
    }
  }
  a {
    display: block;
    color: $color-text-secondary; // UI kit
    text-decoration: none;
    .icon {
      fill: $secondary; // UI kit
    }
    &.active {
      color: $primary;
      .icon {
        fill: $primary;
      }
    }
    &:hover {
      text-decoration: none;
      color: $primary;
      .icon {
        fill: $primary;
      }
    }
  }

  // icon
  .it-ico {
    position: relative;
    font-size: 1.35rem;
    display: block;
    height: 32px;
    &:before {
      margin-right: 0;
    }
  }

  // icon badges ------------------
  .badge-wrapper {
    position: relative;
  }

  .bottom-nav-badge,
  .bottom-nav-alert {
    display: block;
    position: absolute;
    background-color: $primary;
    border: 1px solid $white;
  }

  // icon badge
  .bottom-nav-badge {
    top: 0;
    right: 0;
    min-width: 18px;
    height: 18px;
    padding: 0 4px;
    color: $white;
    font-size: 0.563rem;
    border-radius: 50%;
    font-style: normal;
  }

  // icon alert
  .bottom-nav-alert {
    top: 4px;
    right: 8px;
    min-width: 10px;
    height: 10px;
    font-size: 0.563rem;
    border-radius: 50%;
  }

  // text label
  .bottom-nav-label {
    margin-top: 6px;
    font-size: 0.688rem;
    line-height: 1;
    display: block;
    font-weight: 600;
  }
}
