.public-ui {
  .feedback-form #vf-more-positive,
  .feedback-form #vf-more-negative {
    overflow: hidden;
    max-height: 0;
  }

  .feedback-form #vf-more-positive[aria-expanded='true'],
  .feedback-form #vf-more-negative[aria-expanded='true'] {
    max-height: 800px;
  }

  .feedback-form #vf-more-positive[aria-hidden='true'],
  .feedback-form #vf-more-negative[aria-hidden='true'] {
    visibility: hidden;
  }

  .feedback-form {
    padding: 2rem 0;
    margin: 0;

    .card {
      display: block;
    }

    .title-medium-2-semi-bold {
      font-size: 1.25rem;
      font-weight: 600;
      line-height: 1.2;
      @media (min-width: #{map-get($grid-breakpoints, sm)}) {
        font-size: 1.3333333333rem;
      }
      @media (min-width: #{map-get($grid-breakpoints, md)}) {
        line-height: 1.2;
      }
    }

    .rating-container {
      padding-bottom: 0;

      .ratingOptionContainer {
        position: relative;
      }

      .rating.rating-label {
        position: relative;
        display: flex;
        flex-direction: row;

        label {
          position: relative;
          width: 26px;
          max-width: 26px;
          height: 26px;
          max-height: 26px;
        }

        input.volto-feedback-rating {
          position: relative;
          left: 26px;
          width: 26px;
          margin-left: -24px;
          opacity: 0;
        }

        input.volto-feedback-rating ~ label svg.rating-star {
          fill: #c3cfdb;
          pointer-events: none;
        }

        input.volto-feedback-rating ~ label svg.rating-star.starFilled {
          fill: $primary;
        }

        input.volto-feedback-rating:focus + label {
          border: 0;
          box-shadow:
            0 0 0.2rem $focus-outline-color,
            0 0 0 0.2rem $focus-outline-color !important;
          outline: none;
        }
      }
    }

    .form-check label {
      font-weight: 600;
    }

    .answers-form .form-check:last-of-type {
      margin-bottom: 0;
    }

    .card-wrapper[data-element='feedback'] {
      padding: 1.8rem !important;
    }
    @media (min-width: #{map-get($grid-breakpoints, sm)}) {
      .answers-header,
      .comments-header {
        padding: 0.78rem;
        margin-bottom: 2.111rem;
        font-size: 1rem;

        & > div {
          font-size: 0.7777777778rem;
        }
      }
    }

    .answers-header,
    .comments-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.875rem;
      margin-bottom: 2.375rem;
      box-shadow: 0 0.9375rem 1.325rem -0.9rem rgba(0, 0, 0, 0.1);
      font-size: 1.125rem;
      font-weight: 600;
      line-height: 2;

      > div {
        margin-left: 0.5rem;
        font-size: 0.875rem;
        font-weight: 600;
        line-height: 1.5;
      }

      h6 {
        margin-bottom: 0;
      }
    }

    .comments-step {
      .form-group {
        margin-bottom: 0;
      }

      .invalid-feedback.form-text.text-muted {
        display: block;
        color: var(--bs-danger) !important;
      }

      small {
        padding: 0.25rem 0.5rem;
        margin: 0;
        font-size: 0.777rem;

        &.invalid-feedback {
          display: block;
        }
      }

      label.active {
        color: #596d88;
      }

      textarea {
        border: none;
        border-radius: 0;
        border-bottom: 1px solid #5b6f82;
      }
    }

    .card:after {
      content: none;
    }

    .form-step-actions[aria-hidden='true'] {
      padding: 0rem;

      .next-action,
      .prev-action {
        height: 0;
      }
    }

    .form-step-actions[aria-hidden='false'] {
      .next-action,
      .prev-action {
        padding: 12px 24px;
        visibility: visible;
      }

      &.button-shadow {
        margin-top: 1.25rem;
        box-shadow: 0 -0.6375rem 1.125rem -0.9rem rgba(0, 0, 0, 0.1);
        @media (min-width: #{map-get($grid-breakpoints, sm)}) {
          margin-top: 1.11rem;
        }
      }
    }
  }
}
