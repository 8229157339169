.card-slide-text-template {
  .listing-item {
    h3.title {
      padding: 0 0.5rem 0.2rem;
      background: $primary;
    }
    .enhance-link {
      display: none;
    }
  }
}
