.input-number {
  position: relative;
  width: 100%;

  &.input-number-adaptive {
    width: auto;
    display: inline;
    input[type='number'] {
      width: auto;
      transition: all 0.3s;
    }
  }

  //reset input style
  input[type='number'] {
    appearance: textfield;
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
    }
    &::-ms-clear {
      display: none;
    }

    // color: $color-text-muted;
    padding-right: 32px;
    padding-left: 12px;
    min-width: 86px !important;
    width: 100%;

    &:focus {
      color: $color-text-base;
    }
  }

  //currency + percentage version
  &.input-number-currency,
  &.input-number-percentage {
    &:before {
      position: absolute;
      left: 14px;
      top: 0;
      // color: $color-text-muted;
      font-weight: bold;
      font-size: 1rem;
    }
    input[type='number'] {
      padding-left: 40px;
    }
  }
  &.input-number-currency {
    &:before {
      content: '€';
    }
  }
  &.input-number-percentage {
    &:before {
      content: '%';
    }
  }

  //disabled version
  &.disabled {
    input[type='number'] {
      background-color: transparent;
      color: $gray-label-disabled;
      border-bottom-color: $gray-border-disabled;
      &:hover {
        cursor: not-allowed;
        color: $gray-label-disabled;
      }
    }
    button {
      pointer-events: none;
      &:hover {
        cursor: not-allowed;
      }
      &.input-number-add {
        &:after {
          border-color: transparent transparent $gray-border-disabled transparent;
        }
      }
      &.input-number-sub {
        &:after {
          border-color: $gray-border-disabled transparent transparent transparent;
        }
      }
    }
  }

  //add + sub buttons
  button {
    position: absolute;
    right: 2px;
    transition: opacity 0.1s;
    padding: 0;
    border: none;
    height: 20px;
    width: 24px;
    background: transparent;
    &:after {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
    }
    &:focus,
    &:hover {
      &.input-number-add {
        &:after {
          border-color: transparent transparent $dark transparent; // TODO Check if still necessary
        }
      }
      &.input-number-sub {
        &:after {
          border-color: $dark transparent transparent transparent; // TODO Check if still necessary
        }
      }
    }
    &:focus:not(.focus--mouse) {
      opacity: 1;
    }

    &.input-number-add {
      top: -6px;
      &:after {
        border-width: 0 5px 6px 5px;
        border-color: transparent transparent $neutral-1-a7 transparent; // TODO Check if still necessary
      }
    }
    &.input-number-sub {
      bottom: -6px;
      &:after {
        border-width: 6px 5px 0 5px;
        border-color: $neutral-1-a7 transparent transparent transparent; // TODO Check if still necessary
      }
    }
    &:hover {
      cursor: pointer;
    }
  }
}

//label
label {
  &.input-number-label {
    display: block;
    color: $color-text-base;
    font-weight: 600;
    font-size: $small-font-size;
    cursor: text;
    width: 100%;
    padding: 0 $input-spacing-x;
  }
}

//Desktop
@include media-breakpoint-up(xl) {
  .input-number {
    //add + sub buttons
    button {
      opacity: 0;
    }
    &:hover {
      button {
        opacity: 1;
      }
    }
  }
}
