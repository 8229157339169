.block.listing {
  .full-width {
    height: unset;
  }

  .listing-item picture {
    max-width: unset;
    margin: 0;
  }

  .event-recurrences-more {
    display: flex;
    align-items: center;
    color: $body-color;
    font-size: $card-category-size;
    letter-spacing: $card-category-l-spacing;
  }

  //* custom align button

  .link-more-button-align-right {
    display: flex;
    align-items: center;
    justify-content: right;
    margin: 30px 0;
  }

  //* customizzazione: checkbox linea sotto al titolo
  .title-bottom-line {
    padding-bottom: 15px;
    border-bottom: 1px solid #455b71;
  }
}
