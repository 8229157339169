.block.video {
  .video-inner {
    > div {
      position: relative;
      overflow: hidden;
      max-width: 100%;
      height: 0;
      padding-bottom: 56.25%;

      .icon-play {
        position: absolute;
        z-index: 2;
        top: 50%;
        left: 50%;
        width: 4rem;
        height: 2.6rem;
        border-radius: 15px;
        background-color: rgba(0, 0, 0, 0.7);
        color: #fff;
        cursor: pointer;
        line-height: 2.6rem;
        text-align: center;
        transform: translateX(-50%) translateY(-50%);
      }
    }

    img.placeholder {
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      transform: translateY(-50%);
      min-width: 100%;
      height: unset;
      min-height: 100%;
      object-fit: cover;
    }
  }

  .ui.embed img.placeholder {
    cursor: pointer;
  }

  &.full {
    .video-inner.full-width {
      height: auto;
    }
  }

  @media (max-width: #{map-get($grid-breakpoints, md)}) {
    &.align.right,
    &.align.left {
      .video-inner {
        width: 100%;
        margin-left: 0 !important;
        margin-right: 0 !important;
        float: unset;
      }
      img {
        margin-left: 0 !important;
        margin-right: 0 !important;
        float: unset;
      }
    }
  }
}
