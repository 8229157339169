.card-persona.card-flex {
  .card-image-wrapper {
    display: flex;
    width: 100%;
    flex: 1;
    align-items: flex-start;
  }

  .card-body {
    // TODO Rimuovere important e mettere p-4 come classe nel jsx
    // nel momento in cui riusciremo a togliere il padding 0 important
    // dalla classe .card-big-io-comune .card-body globalmente
    padding: 24px !important;

    .card-text {
      color: $gray-secondary;
    }
  }

  .card-image {
    flex: 0 0 130px;

    img {
      // Dobbiamo vincere contro gli stili del blocco listing
      width: 130px !important;
      height: 130px * 1.4 !important;
      object-fit: cover;
      object-position: center;
    }
  }

  .card-title {
    font-size: $card-big-head-size;
    line-height: $card-big-head-l-h;

    a {
      font-weight: 700;
    }
  }

  span.text {
    font-weight: 600;
    letter-spacing: 1px;
    color: $caption-text;
    font-size: 0.875rem;
  }

  &.listing-item {
    .card-body {
      .category-top {
        width: 50%;

        svg {
          width: auto;
          height: 1.78rem;
        }

        span.text {
          font-size: $card-category-size;
        }
      }

      h3.card-title {
        a {
          font-size: 1.777rem;
          line-height: 1.5rem;
        }
      }

      .card-text {
        font-family: 'Lora' !important;
        color: $card-p-color;
      }
    }
  }
}
