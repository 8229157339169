.public-ui .alertblock {
  img.left-image {
    &.size-s {
      max-width: 50%;
    }
    &.size-m {
      max-width: 70%;
    }
    &.size-l {
      max-width: 100%;
    }
  }

  a.btn.btn-primary.inline-link .icon.external-link {
    fill: $body-color;
  }
}
