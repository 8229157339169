:focus:not(.focus--mouse),
%focus {
  outline: 2px solid $outer-focus-outline !important;
  outline-offset: 2px;

  border: none !important;
  box-shadow: 0 0 0 2px $inner-focus-shadow !important;
}

.skiplinks a:focus:not(.focus--mouse) {
  border: 2px solid;
}
