.card.contacts-card {
  .card-text {
    display: flex;
    flex-wrap: wrap;
    font-size: 1rem;
    @include rem-size(line-height, 28);

    span {
      flex: 1 1 100%;
    }

    .pdc-type {
      font-weight: 600;
      text-transform: capitalize;
    }
  }
}
