.cms-ui .slate-inline-toolbar {
  .ui.buttons {
    flex-wrap: wrap;
  }
  .ui.dropdown .menu > .item {
    &.active {
      color: #007eb1;
      background-color: lighten(#007eb1, 60);
    }
    > .icon {
      margin-right: 0;
    }
  }
}
