.argumentInEvidence {
  .argumentInEvidence-background {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 300px;
    background-color: $primary;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    + .volto-subblocks-wrapper .argumentsCardsWrapper h2,
    + .argumentsCardsWrapper h2 {
      color: $primary-text !important;
    }
  }

  .grid {
    display: grid;
    grid-gap: 1.3rem;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .text-white {
    color: $white;
  }

  .card-body .card-title {
    line-height: 2rem;
  }

  .link-button {
    text-align: center;
  }

  .delete-button {
    top: -31px !important;
  }

  @media (max-width: #{map-get($grid-breakpoints, md) - 1px}) {
    .grid {
      display: grid;
      grid-template-columns: 1fr;
    }

    .container {
      padding: 0 1.3rem;
    }

    .card.card-bg {
      margin-right: 0;
      margin-left: 0;
    }
  }

  .subblock-edit {
    .dragsubblock {
      top: -1rem;
      left: -0.5rem;
    }

    .ui.basic.icon.button.delete-button {
      top: -1rem !important;
      left: 1.5rem;
    }
  }
}
