.it-page-sections-container {
  h2 {
    @extend .mb-3;
    @extend .h4;
    @extend .mt-5;
  }

  h3 {
    @extend .h5;
  }
}

.it-page-sections-container .public-ui,
.public-ui .it-page-sections-container {
  .it-page-section.bg-primary {
    .h2,
    h2,
    .mt-5 {
      &:first-child {
        margin-top: 0 !important;
      }
    }

    p {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.it-carousel-wrapper {
  .it-header-block {
    h2.h4 {
      font-size: 1.555rem;
    }
  }
}
