//mobile
.it-header-slim-wrapper {
  /* stylelint-disable-next-line scss/at-extend-no-missing-placeholder */
  @extend .nav;
  background: $header-slim-bg-color;
  padding: ($header-slim-v-padding-mob $header-general-padding - ($grid-columns * 0.5));
  .it-header-slim-wrapper-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .dropdown-menu {
      top: -$header-slim-dropdown-distance-mob !important;
      @include media-breakpoint-down(md) {
        top: 0 !important;
      }
    }
    a {
      color: $header-slim-text-color;
      text-decoration: none;
      &:hover:not(.active) {
        text-decoration: underline;
      }
      .icon {
        width: $header-slim-icon-size;
        height: $header-slim-icon-size;
        transition: all 0.3s;
        transform-origin: center;
        // set default text color to icon
        fill: $header-slim-text-color;
      }
      &.dropdown-toggle {
        text-transform: uppercase;
        font-size: $header-slim-brand-text-size;
        text-decoration: underline;
        display: flex;
        align-items: center;
        &[aria-expanded='true'] {
          & > .icon:last-of-type {
            transform: scaleY(-1);
          }
        }
      }
      &.it-opener {
        font-size: $header-slim-brand-text-size;
        padding: 0.5rem 0;
        display: block;
        text-decoration: none;
        &[aria-expanded='true'] {
          & > .icon:last-of-type {
            transform: scaleY(-1);
          }
        }
      }
    }
    .navbar-brand {
      color: $header-slim-text-color;
      font-size: $header-slim-brand-text-size;
      padding: 0;
    }
    .it-header-slim-right-zone,
    .header-slim-right-zone {
      // .header-slim-right-zone is .it-header-slim-right-zone now. We're keeping old class here for retrocompatibility
      display: flex;
      align-items: center;
      align-self: flex-start;
      button,  // We're keeping the button's selector here for retrocompatibility
      .it-access-top-wrapper > a {
        background: $header-slim-button-color;
        padding-top: $header-slim-button-v-padding;
        padding-bottom: $header-slim-button-v-padding;
        &:hover {
          background: $header-slim-button-hover-color;
        }
      }
    }

    .nav-mobile {
      ul.link-list {
        margin-top: $v-gap * 2;
        margin-bottom: $v-gap * 3;
        a.list-item {
          &.active {
            color: $white;
            cursor: default;
            text-decoration: none;
          }
          &:hover:not(.active) {
            text-decoration: underline;
          }
        }
      }
    }
  }
}

//Tablet horizontal / small desktop
@include media-breakpoint-up(lg) {
  .it-header-slim-wrapper {
    height: $header-slim-height;
    .it-header-slim-wrapper-content {
      padding: 0 $header-slim-h-padding;
      height: $header-slim-height;
      align-items: center;
      .dropdown-menu {
        top: -$header-slim-dropdown-distance !important;
      }
      .nav-mobile {
        flex-grow: 2;
        ul.link-list {
          margin: 0;
          display: flex;
          padding: 0 $v-gap * 3;
          margin-right: $v-gap * 2;
          border-left: 1px solid rgba($header-slim-text-color, 0.2);
          border-right: 1px solid rgba($header-slim-text-color, 0.2);
          height: $header-slim-height;
          a {
            padding-top: $header-slim-links-padding;
            padding-bottom: $header-slim-links-padding;
            &.active {
              border-bottom: 2px solid $header-slim-text-color;
              text-decoration: none;
            }
          }
        }
      }
      a {
        &.dropdown-toggle {
          padding-top: $header-slim-lang-v-padding;
          padding-bottom: $header-slim-lang-v-padding;
          text-decoration: none;
          .icon {
            margin-left: $v-gap * 0.5;
          }
        }
        &.navbar-brand {
          padding-top: $header-slim-brand-padding;
          padding-bottom: $header-slim-brand-padding;
        }
      }
      .it-header-slim-right-zone,
      .header-slim-right-zone {
        // .header-slim-right-zone is .it-header-slim-right-zone now. We're keeping old class here for retrocompatibility
        height: $header-slim-height;
      }
    }

    padding: 0;
    .collapse:not(.show) {
      display: flex;
      justify-content: flex-end;
    }
  }
  .is-sticky {
    .it-user-wrapper .dropdown-menu.show {
      transform: translate3d(0, 6px, 0) !important;
      padding-bottom: 0.5rem;
    }
    .link-list-wrapper ul li a .icon {
      width: $header-slim-icon-size;
      height: $header-slim-icon-size;
    }
  }
}
