.callout-bg {
  color: #fff;
}

.public-ui
  .it-header-center-wrapper
  .it-header-center-content-wrapper
  .it-brand-wrapper
  a
  .it-brand-tagline.d-none {
  display: block !important;
}
