.slate-toolbar {
  //remove align justify
  #align-menu {
    .menu {
      .block-align-text-justify {
        display: none;
      }
    }
  }
}
