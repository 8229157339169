.block.gridBlock {
  .block.image img {
    aspect-ratio: initial;
  }
}

.blocks-widget-container {
  .block.gridBlock {
    .full-width {
      max-width: 100% !important;
      width: 100% !important;
      left: unset;
      right: unset;
      margin-left: 0 !important;
      margin-right: 0 !important;
    }

    .toolbar {
      top: -50px;
      z-index: 10;
      height: unset;
      right: unset;
    }
  }
}
