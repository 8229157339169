.block.simple-text-block {
  &,
  &.public-ui {
    h3.h4,
    .card .card-body .card-title .simple-text-editor-widget {
      font-weight: 700 !important;
      font-size: 1.75rem !important;

      @include media-breakpoint-up(sm) {
        font-size: 1.777rem !important;
      }
    }
  }
}
