.PageHeaderWrapper {
  .header-infos {
    p {
      margin-bottom: 0.5rem;
    }
  }

  .header-image {
    max-width: 16em;
  }

  .link-list-wrapper .link-list {
    li a {
      margin-bottom: 0.9rem;
      line-height: 1.4rem;
    }

    li:last-child a {
      margin-bottom: 0;
    }
  }

  .readingtime-dates {
    small {
      color: $caption-text;
      font-size: 0.7rem;
      font-weight: 400;
      line-height: 0.8rem;
    }

    p.font-monospace {
      margin-top: 0.2em;
      color: $body-color;
      font-size: 1rem;
      letter-spacing: 0.96px;
      line-height: 1.16rem;
    }
  }
}
