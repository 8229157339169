//mobile
//menu on bottom animation
@keyframes megamenuFadeIn {
  0% {
    opacity: 0;
    margin-top: $dropdown-menu-vertical-shift;
  }
  100% {
    opacity: 1;
    margin-top: $dropdown-menu-vertical-shift;
  }
}
.row.row-column-border > [class^='col-'] .navbar {
  padding: 8px 0;
}
.navbar {
  background: $navbar-bg-color;
  // overlay div
  .overlay {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: $navigation-sidebar-bg-color-mobile;
    display: none;
  }

  // navbar mobile
  .navbar-collapsable {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 100;
    display: none;
    width: 100%; //-occupa sempre tutta lo spazio orizzontale disponibile

    .menu-wrapper {
      background: $navigation-bg-color;
      position: absolute;
      top: 0;
      bottom: 0;
      right: $navigation-h-padding * 2;
      left: 0;
      pointer-events: all;
      overflow-y: auto;
      transform: translateX(-100%);
      transition: all 0.2s cubic-bezier(0.29, 0.85, 0.5, 0.99);
      z-index: 5;
      .navbar-nav {
        padding: $navigation-h-padding 0 0;
        overflow: hidden;

        .dropdown-menu {
          padding-bottom: 0;
        }
      }
    }

    /*lista menu*/
    .navbar-nav {
      padding: $navigation-h-padding 0;

      li {
        a.nav-link {
          color: $navigation-link-color;
          font-weight: 600;
          padding: $navigation-v-padding $navigation-h-padding;
          position: relative;

          &.dropdown-toggle {
            svg {
              fill: $navbar-bg-color;
              transition: all 0.3s;
            }

            &[aria-expanded='true'] {
              .icon {
                transform: scaleY(-1);
              }
            }
          }

          &.active {
            border-left: $navigation-link-active-bar-size solid $navigation-link-active-bar-color;
          }

          &.disabled {
            span,
            .icon {
              opacity: $navigation-disabled-item-mobile-opacity;
            }
          }

          &:hover:not(.active) {
            text-decoration: underline;
          }
        }
      }
    }

    &.expanded {
      .menu-wrapper {
        transform: translateX(0);
        transition: all 0.3s cubic-bezier(0.29, 0.85, 0.5, 0.99);
      }

      .close-div {
        //transform: translateX(0);
        opacity: 1;
      }
    }
  }

  //wrapper pulsante di chiusura menu
  .close-div {
    opacity: 0;
    position: fixed;
    left: auto;
    right: 2px;
    top: $navigation-h-padding;
    background: transparent;
    z-index: 10;
    pointer-events: none;
    transition: all 0.3s cubic-bezier(0.1, 0.57, 0.4, 0.97);
    // pulsante di chiusura menu
    .close-menu {
      background: transparent;
      pointer-events: all;
      color: $navigation-close-button-text-color;
      font-size: $navigation-close-button-text-size;
      padding: 0;
      width: $navigation-close-button-size;
      height: $navigation-close-button-size;
      display: flex;
      align-items: center;
      justify-content: center;
      .icon {
        fill: $white;
      }
    }
  }

  // Dropdown style tipo collapse
  .dropdown-menu {
    background: transparent;
    box-shadow: none;
    padding-top: 0;

    &:before {
      display: none;
    }

    .link-list-wrapper {
      padding-left: $navigation-h-padding;
      padding-right: $navigation-h-padding;
      margin-bottom: $navigation-h-padding;

      h3,
      .link-list-heading {
        text-transform: uppercase;
        font-size: $megamenu-heading-text-size;
        font-weight: $megamenu-heading-font-weight;
        letter-spacing: $megamenu-heading-letter-spacing;
      }

      ul li {
        a {
          &.list-item.active {
            color: $primary-b3;
            text-decoration: underline;

            span {
              color: $primary-b3;
            }
          }

          span {
            color: $navigation-link-color;
          }

          i {
            color: $navigation-link-color;
          }
        }

        .divider {
          width: $megamenu-link-small-line-width;
          background: $neutral-2;
          margin-left: $navigation-h-padding;
        }
      }
    }
  }
}

.custom-navbar-toggler {
  background: none;
  border: none;
  cursor: pointer;

  span {
    color: $navigation-toggle-button-icon-color;
    font-size: $navigation-toggle-button-icon-size;
  }

  svg {
    fill: $navigation-toggle-button-icon-color;
    width: $navigation-hamburger-size;
    height: $navigation-hamburger-size;
    display: block;
  }
}

//reset icon on megamenu
.nav-item.megamenu > a:before {
  display: none;
}

//-------------------INLINE MENU---------------------
.inline-menu {
  .link-list-wrapper {
    .link-list {
      position: relative;

      li {
        a {
          position: relative;
          &.large {
            padding-top: $inline-menu-linklist-v-padding;
            padding-bottom: $inline-menu-linklist-v-padding;
          }
          i {
            color: $primary-a3;
          }

          &:after {
            content: '';
            display: block;
            width: $inline-menu-line-width;
            background: $primary;
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            transform-origin: center;
            transform: scaleY(0);
            transition: all 0.3s;
            z-index: 1;
          }

          &.active {
            span {
              color: $primary;
              text-decoration: none;
            }
          }

          &[aria-expanded='true'] {
            &:after {
              transform: scaleY(1);
            }
          }
        }
      }

      &:after {
        content: '';
        display: block;
        width: $inline-menu-line-width;
        background: linear-gradient(to bottom, rgba($neutral-2-a1, 1) 0%, rgba($neutral-2-a1, 0.3) 100%);
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
      }
    }
  }
}
//Tablet horizontal / small desktop
@media (min-width: #{map-get($grid-breakpoints, lg)}) {
  .row.row-column-border > [class^='col-'] .navbar {
    padding: 0;
  }
  .navbar {
    background: $navigation-bg-color-desktop;
    padding: 0;
    // navbar mobile
    .navbar-collapsable {
      position: relative;
      top: auto;
      right: auto;
      left: auto;
      bottom: auto;
      z-index: auto;
      background: none;
      display: block !important; //-sovrascrive l'inline style di jquery
      transition: none;

      .overlay {
        display: none !important;
      }

      // menu vrapper
      .menu-wrapper {
        position: inherit;
        top: auto;
        bottom: auto;
        right: auto;
        left: auto;
        overflow-y: visible;
        transform: none;
        transition: none;
        background: $navigation-bg-color-desktop;

        .navbar-nav {
          margin-top: 0;
          padding: 0 $navigation-h-padding;
          overflow: inherit;

          .dropdown-menu {
            padding-bottom: $link-list-footer-distance-bottom;
          }
        }
      }

      /*lista menu*/
      .navbar-nav {
        margin-top: 0;
        padding: 0 $navigation-h-padding;

        li {
          // Desktop - compatta e allinea in basso link singoli in caso di label lunghe
          @media (min-width: #{map-get($grid-breakpoints, lg)}) {
            &.nav-item {
              display: flex;
              .nav-link {
                display: flex;
                align-self: flex-end;
                align-items: center;
              }
            }
          }

          a.nav-link {
            font-weight: 400;
            padding: $navigation-v-padding $navigation-h-padding;
            color: $navigation-link-color-desk;
            border-bottom: $navigation-link-active-bar-size solid transparent;

            &.dropdown-toggle {
              svg {
                fill: $navigation-bg-color;
                margin-top: 3px;
              }
            }

            &.focus--mouse {
              border-color: transparent !important;
            }

            &.active {
              border-left: 0;
              border-color: $navigation-link-active-bar-color-desk;
              background: transparent;
            }

            &.disabled {
              span,
              .icon {
                opacity: $navigation-disabled-item-opacity;
              }
            }
          }
        }
      }
    }

    //wrapper pulsante di chiusura menu
    .close-div {
      display: none;
    }

    // Dropdown style tipo collapse
    .dropdown-menu {
      position: absolute;
      top: 100%;
      left: 0;
      z-index: $zindex-dropdown;
      //display: none; // none by default, but block on "open" of the menu
      float: left;
      min-width: $dropdown-min-width;
      padding: $dropdown-padding-y 0 0;
      margin: $dropdown-spacer 0 0; // override default ul
      font-size: $font-size-base; // Redeclare because nesting can cause inheritance issues
      color: $body-color;
      text-align: left; // Ensures proper alignment if parent has it changed (e.g., modal footer)
      list-style: none;
      background-color: $dropdown-bg;
      background-clip: padding-box;
      border: $dropdown-border-width solid $dropdown-border-color;
      @include border-radius($dropdown-border-radius);
      @include box-shadow($dropdown-box-shadow);
      border-top-left-radius: 0;
      border-top-right-radius: 0;

      &.show {
        animation: dropdownFadeIn forwards $dropdown-menu-animation-speed;
        top: calc(100% - #{$dropdown-menu-vertical-shift});
      }

      &:before {
        display: block;
      }

      .link-list-wrapper {
        padding-left: 0;
        padding-right: 0;
        margin-bottom: 0;

        h3,
        .link-list-heading {
          color: $neutral-1;
        }

        a {
          h3,
          .link-list-heading {
            color: $primary;
          }
        }

        ul li {
          a {
            span,
            i {
              color: $primary;
            }
          }
        }
      }
    }

    .dropdown-toggle {
      display: block;
      justify-content: inherit;
    }
  }
  .custom-navbar-toggler {
    display: none;
  }
  .has-megamenu {
    .navbar-collapsable {
      width: 100%;
    }
  }
  .nav-item.megamenu {
    position: static;

    > a.dropdown-toggle {
      position: relative;

      &:before {
        content: '';
        position: absolute;
        top: auto;
        bottom: -$dropdown-menu-notch-base-size - 6;
        left: $dropdown-menu-notch-position-x;
        width: $dropdown-menu-notch-base-size * 3;
        height: $dropdown-menu-notch-base-size * 3;
        border-radius: $dropdown-menu-radius;
        background-color: $dropdown-bg;
        transform: rotate(45deg);
        opacity: 0;
        display: block;
        transition: opacity $dropdown-menu-animation-speed * 0.5 cubic-bezier(0.1, 0.57, 0.36, 0.99);
      }
    }

    a.dropdown-toggle {
      &.show {
        &:before {
          opacity: 1;
        }
      }
    }
  }
  .megamenu {
    .dropdown-menu {
      min-width: auto;
      left: $navigation-h-padding;
      right: $navigation-h-padding;
      animation-timing-function: cubic-bezier(0.1, 0.57, 0.36, 0.99);
      //hide arrow
      &:before {
        display: none;
      }

      &.show {
        animation: megamenuFadeIn forwards $dropdown-menu-animation-speed * 0.5;
      }
    }
  }
}
