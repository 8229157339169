/*focus color on blue primary*/
/*HEADER*/
.public-ui {
  .it-header-wrapper,
  .it-footer-main,
  .it-footer-small-prints {
    :focus:not(.focus--mouse) {
      border-color: $focus-on-primary !important;
      box-shadow: 0 0 0 2px $focus-on-primary !important;
    }
  }
}

body.subsite {
  .public-ui {
    .it-header-wrapper {
      .it-nav-wrapper {
        :focus:not(.focus--mouse) {
          border-color: $focus-outline-color !important;
          box-shadow: 0 0 0 2px $focus-outline-color !important;
        }
      }
    }
  }
}

.public-ui {
  body:not(.subsite) .bg-primary,
  .sliderTemplate .slide-title {
    :focus:not(.focus--mouse),
    .react-select__control--is-focused {
      border-color: $focus-on-primary !important;
      box-shadow: 0 0 0 2px $focus-on-primary !important;
    }
  }
}
