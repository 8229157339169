label {
  color: $input-label-color;
}
fieldset {
  legend {
    background-color: transparent;
    font-weight: 700;
    line-height: calc(#{$input-height} - 1px);
    transition: 0.2s ease-out;
    cursor: text;
    display: block;
    max-width: 100%;
    width: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 $input-spacing-x;
    z-index: 1;
    font-size: $small-font-size;
    color: $input-label-color;
    margin-bottom: 0;
    float: none;
  }
}
.form-text {
  color: $color-text-secondary;
}
.form-group {
  position: relative;
  margin-bottom: $form-group-margin-bottom;
  margin-top: $form-group-margin-top;

  input,
  optgroup,
  textarea {
    color: $color-text-muted;
  }

  label {
    background-color: transparent;
    position: absolute;
    font-weight: 600;
    line-height: calc(#{$input-height} - 1px);
    transition: 0.2s ease-out;
    top: 0;
    font-size: 1rem;
    cursor: text;
    color: $input-color-placeholder;
    display: block;
    max-width: 100%;
    width: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 $input-spacing-x;
    z-index: 1;
    &.active {
      transform: translateY(-75%);
      font-size: $small-font-size;
      color: $input-label-color;
    }
  }

  small.form-text {
    margin: 0;
    padding: $input-spacing-x * 0.5 $input-spacing-x;
    font-size: $small-font-size;
  }

  input[type='time'] ~ label {
    transform: translateY(-75%);
    font-size: $small-font-size;
  }
}

::placeholder {
  font-weight: 600;
  color: $input-color-placeholder;
}

input[type='date'],
input[type='datetime-local'],
input[type='email'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='time'],
input[type='url'],
textarea {
  border: none;
  border-bottom: 1px solid $input-border;
  border-radius: 0;
  padding: $input-spacing-y $input-spacing-x;
  outline: 0;
  height: $input-height;
  width: 100%;
  box-shadow: none;
  transition: none;
  font-weight: 700;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  &::placeholder {
    color: $input-color-placeholder;
    font-weight: 600;
  }
}

textarea {
  border: 1px solid $input-border;
  height: auto;
}

.form-control {
  background-position: center right !important;
  background-repeat: no-repeat !important;
  background-size: 45px 45% !important;
  &:disabled,
  &[readonly] {
    cursor: not-allowed;

    & + label {
      background-color: transparent;
      cursor: not-allowed;
    }
  }
  &:focus,
  &:active {
    box-shadow: none !important;
  }
  .was-validated &:valid,
  &.is-valid {
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%2300cc85' viewBox='0 0 192 512'%3E%3Cpath d='M435.848 83.466L172.804 346.51l-96.652-96.652c-4.686-4.686-12.284-4.686-16.971 0l-28.284 28.284c-4.686 4.686-4.686 12.284 0 16.971l133.421 133.421c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-28.284-28.284c-4.686-4.686-12.284-4.686-16.97 0z'/%3E%3C/svg%3E");
  }

  .was-validated &:invalid,
  &.is-invalid {
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23f73e5a' viewBox='0 0 384 512'%3E%3Cpath d='M231.6 256l130.1-130.1c4.7-4.7 4.7-12.3 0-17l-22.6-22.6c-4.7-4.7-12.3-4.7-17 0L192 216.4 61.9 86.3c-4.7-4.7-12.3-4.7-17 0l-22.6 22.6c-4.7 4.7-4.7 12.3 0 17L152.4 256 22.3 386.1c-4.7 4.7-4.7 12.3 0 17l22.6 22.6c4.7 4.7 12.3 4.7 17 0L192 295.6l130.1 130.1c4.7 4.7 12.3 4.7 17 0l22.6-22.6c4.7-4.7 4.7-12.3 0-17L231.6 256z'/%3E%3C/svg%3E");
  }

  &.warning {
    background-size: 25px 45% !important;
    border-color: $warning;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23ff9900' viewBox='0 0 192 512'%3E%3Cpath d='M176 432c0 44.112-35.888 80-80 80s-80-35.888-80-80 35.888-80 80-80 80 35.888 80 80zM25.26 25.199l13.6 272C39.499 309.972 50.041 320 62.83 320h66.34c12.789 0 23.331-10.028 23.97-22.801l13.6-272C167.425 11.49 156.496 0 142.77 0H49.23C35.504 0 24.575 11.49 25.26 25.199z'/%3E%3C/svg%3E");
  }

  &.is-valid ~ .warning-feedback {
    display: block;
  }
}

.form-group.active .form-file-name {
  padding-bottom: 1.95rem;
}

.form-control-plaintext {
  padding: $input-spacing-y $input-spacing-x;
  background-color: $white !important;
  cursor: not-allowed;

  & + label {
    cursor: not-allowed;
  }
}

.warning-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.777rem;
  color: $warning;
}

.valid-feedback,
.invalid-feedback,
.warning-feedback {
  margin-left: 0.5rem;
}

// Input group
.input-group {
  .input-group-text {
    padding: $input-spacing-y $input-spacing-x;
    border-bottom: 1px solid $input-border;
    height: 100%;
  }

  .input-group-prepend {
    margin-right: 0;

    .icon {
      fill: $icon-secondary;
    }

    & ~ label {
      left: 2.25rem;
      max-width: calc(100% - 2.25rem);
    }

    .btn {
      border-radius: 4px 0 0 4px;
    }
  }

  .input-group-append {
    margin-left: 0;
    .btn {
      padding-top: 0;
      padding-bottom: 0;
      border-bottom: 1px solid $input-border;
      border-radius: 0 4px 4px 0;
      box-shadow: none;
      height: 100%;
      &:focus:not(.focus--mouse) {
        box-shadow: inset 0 0 0 2px $focus-outline-color;
      }
    }
  }
}

.input-group-lg {
  & > .form-control,
  & > .input-group-prepend > .input-group-text,
  & > .input-group-append > .input-group-text,
  & > .input-group-prepend > .btn,
  & > .input-group-append > .btn {
    padding: 0 1rem;
    border-radius: 0;
  }
}

.form-check {
  padding-left: 0;
  margin-top: 0.5rem;
  position: relative;
  [type='checkbox'],
  [type='radio'] {
    position: absolute;
    opacity: 0;
    left: 9px;
    top: 9px;
    margin-left: 0;
    margin-top: 0;

    + label {
      position: relative;
      padding-left: 36px;
      cursor: pointer;
      display: inline-block;
      height: 32px;
      line-height: 32px;
      font-size: 1rem;
      user-select: none;
      margin-bottom: 0.5rem;
    }

    //focus
    &:focus + label {
      @extend %focus;
    }

    &:focus.focus--mouse + label {
      @extend %focusmouse;
    }
  }

  [type='checkbox'] {
    + label::after,
    + label::before {
      content: '';
      left: 0;
      position: absolute;
      transition: 0.2s ease-out;
      z-index: 1;
      border-style: solid;
      border-width: 2px;
    }

    + label::before {
      top: 0;
      width: 17px;
      height: 17px;
      border: 1px solid #e6e9f2;
      border-radius: 1px;
      margin: 2px 5px;
      transform: rotateZ(37deg);
      transform-origin: 100% 100%;
    }

    + label::after {
      border-radius: 4px;
      height: 20px;
      width: 20px;
      margin: 6px 5px;
      top: 0;
    }

    &:checked + label::before {
      top: 6px;
      left: 1px;
      width: 8px;
      height: 13px;
      border-style: solid;
      border-width: 2px;
      border-color: transparent #fff #fff transparent;
      transform: rotate(40deg);
      backface-visibility: hidden;
      transform-origin: 100% 100%;
      opacity: 0.8;
    }

    &:checked + label::after {
      border-color: $primary;
      background-color: $primary;
      z-index: 0;
    }

    &:not(:checked) + label::after {
      background-color: transparent;
      border-color: $neutral-1-a7;
      z-index: 0;
    }

    &:not(:checked) + label::before {
      width: 0;
      height: 0;
      border-color: transparent;
      left: 6px;
      top: 10px;
    }

    &:disabled + label {
      cursor: not-allowed;
      opacity: 1;
    }

    &:disabled:not(:checked) + label::after {
      border-color: #e6e9f2;
      background-color: #fff;
    }

    &:disabled:checked + label::after {
      background-color: #e6e9f2;
      border-color: #e6e9f2;
    }
  }

  [type='radio'] {
    + label {
      transition: 0.2s ease-out;

      &::after,
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        margin: 5px;
        width: 22px;
        height: 22px;
        z-index: 0;
        border-radius: 50%;
        border-style: solid;
        border-width: 2px;
        transition: 0.2s ease-out;
      }
    }

    &:not(:checked) + label {
      &::after,
      &::before {
        border-color: $input-border;
      }

      &:after {
        z-index: -1;
        transform: scale(0);
      }
    }

    &:checked + label {
      &::after {
        border-color: $primary;
        background-color: $primary;
        z-index: 0;
        transform: scale(0.64);
      }

      &::before {
        border-color: $primary;
      }
    }

    &:disabled {
      & + label {
        cursor: not-allowed;
      }

      &:not(:checked) + label {
        &::after,
        &::before {
          border-color: $gray-border-disabled;
        }
      }

      &:checked + label {
        &::after {
          border-color: $gray-border-disabled;
          background-color: $gray-disabled;
        }

        &::before {
          border-color: $gray-border-disabled;
        }
      }
    }
  }

  // gruppi
  &.form-check-group {
    padding: 0 0 8px 0;
    margin-bottom: 16px;
    box-shadow: inset 0 -1px 0 0 rgba(1, 1, 1, 0.1);

    [type='checkbox'] + label,
    [type='radio'] + label {
      position: static;
      padding-left: 0;
      padding-right: 52px;

      &::after,
      &::before {
        right: 15px;
        left: auto;
      }
    }

    [type='checkbox']:checked + label::before {
      right: 26px;
    }

    [type='radio']:checked + label::before {
      right: 15px;
    }

    .form-text {
      margin: 0;
      padding-right: 52px;
      display: block;
      margin-bottom: $v-gap * 2;
    }

    label {
      font-weight: 600;
    }
    input.semi-checked:not(:checked) + label::before {
      right: 19px;
      left: auto;
    }
  }
  input.semi-checked:not(:checked) + label::before {
    top: 13px;
    left: 4px;
    width: 12px;
    height: 2px;
    border-style: none;
    border-width: 0;
    border-color: transparent;
    transform: none;
    backface-visibility: hidden;
    opacity: 1;
    background: $white;
  }

  input.semi-checked:not(:checked) + label::after {
    border-color: $primary-a5; // TODO: Not found
    background-color: $primary-a5; // TODO: Not found
    z-index: 0;
  }
}
