.contenttype-venue {
  .page-header {
    p.h2 {
      @include rem-size(font-size, 28);
      @include rem-size(line-height, 32);
      color: $text-color;
      font-weight: 600;
      @include margin-size(margin-bottom, 16);
      @include margin-size(margin-top, 16);
    }

    p {
      color: $text-color;
    }
  }

  section#metadata {
    h5 {
      color: $gray-secondary;
      font-family: $font-family-base;
    }
  }
}
