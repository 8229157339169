$space-left: 1rem;

.table-of-contents {
  // template for "Indice dei contenuti"
  .ui.bulleted.list {
    padding-left: 0;

    & .ui.bulleted.list {
      display: flex;
      flex-direction: column;
      padding-top: 0.5rem;
      padding-left: 0;

      [class^='item headline-'] {
        margin-left: $space-left;
      }

      .item:last-child {
        padding-bottom: 0;
      }
    }

    .item {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-bottom: 0.6rem;
      font-size: 0.9rem;
      list-style: none;

      a {
        display: inline-block;
        margin-left: 1rem;
        text-decoration: none;
      }

      &.headline-2 {
        width: 100%;
        margin-top: 0.5rem;
        font-size: 1rem;
        font-weight: 600;
        width: 100%;

        &::before {
          position: absolute;
          top: 0.4rem;
          width: 0;
          height: 0;
          border-top: 0.4rem solid transparent;
          border-bottom: 0.4rem solid transparent;
          border-left: 0.5rem solid $link-color;
          content: '' !important;
          list-style: none;
        }
      }

      &:not(.headline-2)::before {
        position: relative;
        position: absolute;
        top: 0.4rem;
        display: inline-block;
        width: 0.55rem;
        min-width: 0.55rem;
        height: 0.55rem;
        min-height: 0.55rem;
        border-top: 0.1rem solid $link-color;
        border-right: 0.1rem solid $link-color;
        content: '' !important;
        list-style: none;
        transform: rotate(45deg);
      }
    }
  }

  ol {
    counter-reset: item;
    list-style-type: none;
  }

  ol > li {
    counter-increment: item;
  }

  ol > li::before {
    content: counters(item, '.') '. ';
  }

  & > ol.ordered.list {
    padding-left: 0;

    li {
      padding: 0.25rem 0;
    }
  }
}
