.ribbon-card-template {
  .card {
    .flag-icon {
      width: $ribbon-width;
    }

    .dates {
      font-size: 0.8em;
      text-transform: uppercase;
    }

    .etichetta {
      position: absolute;
      top: 3rem;
      right: 0px;
      left: auto;
      max-width: calc(100% - $ribbon-spacing-h * 3 - $ribbon-width);
      justify-content: inherit;

      .icon {
        margin-left: auto;
      }
    }

    h3.card-title {
      font-size: 1.3rem;
      font-weight: 700;
      line-height: 1.44444rem;

      a {
        display: inline-block; //per avere il bordo tutto unito al focus di un link, ad esempio nelle card
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    &.show_detail_link {
      .card-title {
        a {
          color: $text-color;
          text-decoration: none;

          &:hover,
          &:active {
            color: $link-color;
            text-decoration: underline;
          }
        }
      }
    }
  }
  @include media-breakpoint-down(md) {
    .card.card {
      .flag-icon.flag-icon {
        width: 20px;
        height: 35px;
        margin-left: 1.3em;

        &::after {
          border-right: 10px solid transparent;
          border-left: 10px solid transparent;
        }
      }

      .card-body {
        padding: 1.3em;
      }
    }

    .etichetta.etichetta {
      top: 0.6em !important;
      right: 0em !important;
      left: auto;
      width: 50%;
      padding: 0em 0.5em;
      font-size: 0.6rem;
    }
  }

  @include media-breakpoint-between(md, xxl) {
    .etichetta.etichetta {
      right: 0.5em !important;
      left: auto;
      width: 50%;
      padding: 0em 0.5em;
    }
  }
}
