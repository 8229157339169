$sidebarFormBg: #fafafa;

#sidebar,
.cms-ui {
  .ui.form {
    .ui.input input[type='email'],
    .ui.input input[type='number'],
    .ui.input input[type='password'],
    .ui.input input[type='search'],
    .ui.input input[type='tel'],
    .ui.input input[type='time'],
    .ui.input input[type='text'],
    .ui.input input[type='url'],
    textarea,
    .slate-editor {
      padding-right: 10px;
      padding-left: 10px;
      margin-top: 0.5rem;
      background: $sidebarFormBg;

      &:focus {
        background: $sidebarFormBg;
        color: #666;
      }
    }

    .react-select-container {
      &,
      .react-select__control {
        background: $sidebarFormBg;
      }

      .react-select__control {
        padding-right: 5px;
        padding-left: 5px;
      }
    }

    .field.inline.textarea textarea {
      padding: 10px;
    }

    .blocks-widget-container {
      .slate-editor {
        border-bottom: none;
        background-color: transparent;
      }
    }

    .ui.segments {
      .accordion .ui.tertiary.segment {
        font-size: 0.8rem;
      }
    }
  }
}

.cms-ui .ui.checkbox input:focus ~ label,
#toolbar .ui.checkbox input:focus ~ label {
  color: #000;
}

.recurrence-widget {
  svg {
    height: 35px !important;
    margin-top: 15px !important;
  }
}
