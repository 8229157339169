.contenttype-servizio {
  section#submit-request {
    h2#header-submit-request {
      //nascondi il titolo 'Accedere al servizio online'
      //@extend .visually-hidden;
      //stili di .visually-hidden perche non viene applicato con l'@extend
      position: absolute !important;
      width: 1px !important;
      height: 1px !important;
      padding: 0 !important;
      margin: -1px !important;
      overflow: hidden !important;
      clip: rect(0, 0, 0, 0) !important;
      white-space: nowrap !important;
      border: 0 !important;
    }
  }
}
