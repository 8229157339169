$header-image-argomento-height: 390px;
$header-image-argomento-height-mobile: 300px;

.ArgomentoTitleWrapper {
  display: flex;
  max-width: 100%;
  min-height: 350px;
  flex-wrap: wrap;
  padding: 50px 70px 80px 70px;
  margin: auto;

  background-color: $white;

  .image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $white;

    img {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  .title-description-wrapper {
    flex: 1 1 500px;
    padding-right: 30px;

    h2 {
      font-size: 1em;
    }
  }

  .description {
    font-family: $font-family-sans-serif;
    font-weight: 400;
  }

  .link-button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px 0;
  }

  .card {
    border-radius: 4px 0px 0px 4px;

    &::after {
      content: none;
    }

    .card-body {
      a {
        &:link {
          text-decoration: none;
        }

        &:active {
          text-decoration: underline;
        }

        &:visited {
          text-decoration: none;
        }

        &:hover {
          text-decoration: underline;
        }
      }

      .category-top {
        .data:before {
          content: none;
        }
      }

      .card-text {
        font-family: $font-family-sans-serif;
        font-size: 0.889em;
        font-weight: 400;
      }
    }

    &.border-left-card {
      // &::before {
      //   position: absolute;
      //   right: 0;
      //   bottom: 0;
      //   left: 0;
      //   display: block;
      //   width: 8px;
      //   height: 100%;
      //   background: $secondary;
      //   content: '';
      //   @extend .rounded-start;
      // }
    }
  }
}

.contenttype-pagina-argomento {
  #portal-header-image {
    position: relative;

    div {
      position: absolute;
      z-index: -1;
      overflow: hidden;

      width: 100%;
      height: $header-image-argomento-height;

      picture {
        display: block;
        width: 100%;
      }

      img {
        position: absolute;
        z-index: -1;
        top: 50%;
        left: 50%;
        width: unset !important;
        min-width: 100%;
        min-height: $header-image-argomento-height;
        margin: 0;
        margin-right: -50%;
        transform: translate(-50%, -50%);
      }
    }

    &:after {
      position: absolute;
      z-index: -1;
      width: 100%;
      height: $header-image-argomento-height;
      background-color: black;
      content: '';
      opacity: 0.3;
    }
  }

  &.cms-ui {
    #portal-header-image {
      &:after {
        content: none;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .contenttype-pagina-argomento {
    #portal-header-image {
      position: relative;

      div {
        height: $header-image-argomento-height-mobile;

        img {
          min-height: $header-image-argomento-height-mobile;
        }
      }

      &::after {
        height: $header-image-argomento-height-mobile;
      }
    }

    .ArgomentoTitleWrapper {
      padding: 40px 35px 40px 35px;

      .a-portata-di-click {
        margin-top: 20px;
      }
    }
  }
}
