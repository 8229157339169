@media print {
    .public-ui {
        .it-header-center-wrapper{
            .it-header-center-content-wrapper {
                .it-brand-wrapper {
                    // Inverto i colori essendo il logo bianco su bianco
                    img.icon {
                      filter: invert(100%);
                    }
                }
            }
        }
    }
}
