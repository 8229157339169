.block .external-source {
  font-size: 0.8rem;
  font-weight: bold;
  margin-top: 1rem;
  color: $primary;
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
}

.block.cardSlideUpTextTemplate {
  .card-slide-text-template .box .external-source {
    color: #fff;
  }
}
