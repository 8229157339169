.subsite-header {
  position: relative;
  overflow: hidden;
  background-color: $primary;
  background-size: cover;

  .text {
    z-index: 2;
    width: 100%;
    color: $primary-text;
    font-size: 1.5em;
    font-style: italic;
    line-height: 1.5em;
    text-align: center;

    h1,
    h2,
    h3,
    h4,
    a {
      color: $primary-text;
      font-family: $font-family-sans-serif;
      font-style: normal;
    }

    .container {
      @media (min-width: #{map-get($grid-breakpoints, md)}) {
        padding: 2em 8em;
      }
    }

    p {
      margin: 0;
    }

    h3 {
      margin-bottom: 0.5em;
      font-size: 1.7em;
    }

    a {
      @extend .btn;
      @extend .btn-primary;
      font-size: 1rem;
    }

    &.subsite-internal-page {
      text-align: left;

      .container {
        @media (min-width: #{map-get($grid-breakpoints, md)}) {
          padding: 2em 0em;
        }
      }
    }
  }
}
