//mobile
.navbar {
  &.it-navscroll-wrapper {
    background: $navscroll-bg-color;

    .it-back-button {
      padding: $navscroll-backbutton-padding;
      font-weight: $navscroll-backbutton-weight;
      box-shadow: $navscroll-bottom-box-shadow;
      text-decoration: none;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      background: $navscroll-bg-color;
      z-index: 10;
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.3s 0s, visibility 0s 0.3s;
      i {
        transform: scale(0.8);
        transform-origin: center;
        display: inline-block;
        margin-right: $v-gap;
      }
      &.show {
        transition: opacity 0.3s 0s, visibility 0s 0s;
        visibility: visible;
        opacity: 1;
      }
    }
    .link-list-wrapper {
      ul {
        li {
          a {
            font-size: $navscroll-font-size;
            font-weight: $navscroll-primary-font-weight;
            line-height: $navscroll-font-size;
            padding-top: $navscroll-links-padding;
            padding-bottom: $navscroll-links-padding;
            &.active {
              border-left: $navscroll-selection-link-left;
              span {
                color: $primary;
                text-decoration: none;
              }
            }
          }
          ul {
            li {
              padding-top: 0;
              padding-bottom: 0;
              a {
                font-weight: 400;
                &.active {
                  font-weight: normal;
                  border-left: none;
                  span {
                    text-decoration: underline;
                    color: $navscroll-secondary-active-color;
                  }
                }
              }
            }
          }
        }
      }
    }
    h3 {
      font-weight: $sidebar-heading-font-weight;
      margin-bottom: $sidebar-heading-bottom-margin;
      font-size: $sidebar-heading-text-size;
      margin-top: $sidebar-heading-margin-top;
      letter-spacing: $sidebar-heading-letter-spacing;
      text-transform: uppercase;
      padding-left: $v-gap * 3;
      padding-right: $v-gap * 3;
    }
    .custom-navbar-toggler {
      display: flex;
      color: $navscroll-toggler-color;
      align-items: center;
      font-weight: $navscroll-button-text-weight;
      cursor: pointer;
      span.it-list {
        color: $navscroll-toggler-color;
        margin-right: $v-gap;
      }
    }
    .custom-navbar-progressbar {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
    }
    .menu-wrapper {
      padding: $v-gap * 10 0 $v-gap * 3;
      right: 0;
    }
  }
  // bottom position
  &.it-bottom-navscroll {
    box-shadow: $navscroll-bottom-box-shadow;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: auto;
    z-index: 99999;
  }
  // top position
  &.it-top-navscroll {
    box-shadow: $navscroll-top-box-shadow;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: auto;
    z-index: 8;
  }
}
//Tablet horizontal / small desktop
@include media-breakpoint-up(lg) {
  .navbar {
    &.it-navscroll-wrapper {
      background: $navscroll-bg-color-desk;
      .it-back-button {
        display: none !important;
      }
      .custom-navbar-progressbar {
        display: none !important;
      }
      .link-list-wrapper {
        ul {
          li {
            a {
              font-size: $navscroll-font-size;
              font-weight: $navscroll-primary-font-weight;
              line-height: $navscroll-font-size;
              padding-top: $navscroll-links-padding;
              padding-bottom: $navscroll-links-padding;
              &.active {
                border-left: $navscroll-selection-link-left;
                span {
                  color: $primary;
                  text-decoration: none;
                }
              }
            }
            ul {
              li {
                padding-top: 0;
                padding-bottom: 0;
                a {
                  font-weight: 400;
                  &.active {
                    font-weight: normal;
                    border-left: none;
                    span {
                      text-decoration: underline;
                      color: $navscroll-secondary-active-color;
                    }
                  }
                }
              }
            }
          }
        }
      }
      h3 {
        font-weight: $sidebar-heading-font-weight;
        margin-bottom: $sidebar-heading-bottom-margin;
        font-size: $sidebar-heading-text-size;
        margin-top: $sidebar-heading-margin-top;
        letter-spacing: $sidebar-heading-letter-spacing;
        text-transform: uppercase;
        padding-left: $v-gap * 3;
        padding-right: $v-gap * 3;
      }
      .custom-navbar-toggler {
        display: none;
      }
      .menu-wrapper {
        padding: $v-gap * 2 0 $v-gap * 3;
        background: $navscroll-bg-color-desk;
      }
    }
    // bottom position
    &.it-bottom-navscroll {
      box-shadow: none;
      position: inherit;
      z-index: 9;
      &.affix-top {
        position: sticky;
        top: 0;
        left: inherit;
        right: inherit;
      }
    }
    // top position
    &.it-top-navscroll {
      box-shadow: none;
      position: inherit;
      &.affix-top {
        position: sticky;
        top: 0;
        left: inherit;
        right: inherit;
      }
    }
    // left line
    &.it-left-side {
      border-left: 1px solid $navscroll-line-color;
    }
    // right line
    &.it-right-side {
      border-right: 1px solid $navscroll-line-color;
    }
  }
}
