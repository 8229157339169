.point-list-wrapper {
  .point-list {
    .point-list-aside {
      &.point-list-warning {
        width: 6.8rem;
        border-color: currentColor;
        border-right-width: 2px;
        color: $link-color;

        .point-date {
          display: flex;
          align-items: flex-end;
        }

        .point-month {
          text-transform: none;
        }
      }
    }

    .point-list-aside.point-list-warning:after {
      right: -7px;
      width: 0.67em;
      height: 0.67em;
      background-color: $primary;
    }

    .point-list-content {
      .card-teaser {
        &.card {
          padding: 0.8em;
        }
      }
    }
  }
}
