body.contenttype-event {
  .parteciperanno-section {
    clear: both;
  }

  .supported-by {
    margin-bottom: 1rem;
  }

  #date-e-orari .point-list-wrapper {
    .point-list-aside.point-list-warning {
      width: 7.5rem;
    }

    .point-list-content {
      .card {
        .card-body {
          .card-title {
            font-weight: 700;
          }
        }
      }
    }
  }
}
