//mobile
.progress-donut-wrapper {
  width: 128px;
  height: 128px;
  position: relative;
  .progressbar-text {
    color: $color-text-base !important;
    font-weight: 700;
    z-index: 3;
  }
  svg {
    position: relative;
    path:first-child {
      display: none;
    }
  }
  &:after {
    content: '';
    background: $white;
    width: 67px;
    height: 67px;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    position: absolute;
    border-radius: 50%;
    z-index: 1;
  }
  &:before {
    content: '';
    background: $color-background-secondary-lighter; // UI Kit
    width: 110px;
    height: 110px;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    position: absolute;
    border-radius: 50%;
    z-index: 0;
  }
}

//Tablet vertical
@include media-breakpoint-up(md) {
  .progress-donut-wrapper {
    width: 180px;
    height: 180px;
    &:after {
      width: 94px;
      height: 94px;
    }
    &:before {
      content: '';
      width: 150px;
      height: 150px;
    }
  }
}
