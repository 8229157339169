.block.listing {
  .timeline-template {
    .enhance-link {
      display: none;
    }

    .it-timeline-wrapper {
      .col-12:nth-child(odd) .card-wrapper .card {
        text-align: left;
        &:after {
          content: unset;
        }
      }
      .timeline-element .card {
        background: #fff;
        .card-title {
          text-transform: initial;
        }
      }
      .it-pin-wrapper {
        &.it-now {
          .pin-icon,
          .pin-text,
          .pin-text span:after {
            background-color: lighten($primary, 20%);
          }
        }
      }
    }

    .list-template {
      .card {
        .card-image {
          width: 100%;
          min-width: unset;
          max-width: unset;
        }
        @media (min-width: #{map-get($grid-breakpoints, md)}) {
          .card-image {
            min-width: 130px;
            max-width: 130px;
          }
        }

        &.Image {
          //content-type Image
          .card-text {
            flex-direction: column;
          }
          .card-image {
            min-width: 100%;
            max-width: 100%;

            margin-right: 0 !important;
            margin-bottom: 0.5rem;
            .img-wrapper {
              width: 100%;
              display: block;
              img {
                width: 100%;
              }
            }
          }
          &.no-after {
            &::after {
              content: unset;
            }
          }
        }

        .card-text {
          flex-wrap: wrap;
          .card-content {
            flex: 1 1 0;
          }
          .card-infos-bottom {
            flex: 1 1 100%;
          }
        }
      }
    }
  }
}
