@media print {
  .public-ui
    .it-header-center-wrapper
    .it-header-center-content-wrapper
    .it-brand-wrapper
    a
    .it-brand-text {
    font-size: 1rem !important;
  }
  .public-ui h1 {
    font-size: 1.7rem;
  }
  body.contenttype-document {
    h1 {
      margin-top: 2rem;
    }
  }
  button.btn.immersive-reader-launch-button,
  .page-sharing.d-inline,
  #page-actions {
    display: none !important;
  }
  .block.remote-counter .block-content,
  .block.remote-counter .block-content a,
  .block.count_down h2,
  .bg-secondary,
  .bg-secondary .button-tertiary,
  .btn-tertiary,
  .public-ui .btn-tertiary,
  a.btn-tertiary,
  .public-ui a.btn-tertiary,
  div.block.cta-block .cta-block-wrapper .cta-tile-text,
  div.block.cta-block .cta-block-wrapper .cta-tile-text h2,
  div.block.cta-block .cta-block-wrapper .cta-tile-text a,
  .block.alertblock .bg-alert-warning,
  .block.alertblock .bg-alert-warning h2,
  .block.alertblock .bg-alert-danger,
  .block.alertblock .bg-alert-danger h2 {
    color: #000 !important;
  }

  div.block.cta-block .cta-block-wrapper {
    border: 1px solid black;
  }

  .block.listing.attachmentCardTemplate .simple-card-compact-template {
    .card-wrapper.card-teaser-wrapper.card-teaser-block-3 {
      .card-teaser {
        flex: 1 1 100%;
        margin: 0;
        padding: 12px !important;

        .card-body {
        }
      }
    }
  }

  div.block.listing.mediaWithModalTemplate {
    .full-width .container .col-item {
      .card-wrapper {
        padding-bottom: 0;
      }
      max-width: 100%;
      .img-responsive-wrapper {
        .img-responsive {
          padding-bottom: 17%;
          width: 30%;
        }
      }
    }
  }
}
