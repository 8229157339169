.block.testo_riquadro_immagine {
  .card {
    .card-body {
      .card-text {
        .slate-editor * {
          line-height: 1.5em;
          font-family: 'Lora, Georgia, serif';
          font-size: 1.1rem;
        }
      }
    }
  }
}
