.modal .modal-dialog.gallery-preview .modal-content {
  position: relative;

  .modal-body {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    max-height: 100%;
    position: unset; //per mettere i bottoni prev e next relative a .modal-content

    justify-self: stretch;
    justify-content: center;
    padding: 1.25rem;
  }
  .item-preview {
    display: grid;
    max-height: 100%;
    min-height: 100%;
    margin-bottom: 0;
    position: unset; //per mettere i bottoni prev e next relative a .modal-content
    img {
      max-height: 100%;
      min-height: 100%;
    }

    .block.video {
      .video-inner {
        video {
          height: 100%;
        }
      }
    }
    button.btn.prev {
      left: -3rem;
    }
    button.btn.next {
      right: -3rem;
    }
  }
}

@media (max-width: #{map-get($grid-breakpoints, md)}) {
  .modal .modal-dialog {
    &.gallery-preview {
      margin: 15px;
      .modal-content .item-preview {
        button.btn {
          padding: 5px;
          width: 1.3rem;
          height: 3rem;
        }

        button.btn.prev {
          left: -0.9rem;
        }
        button.btn.next {
          right: -0.9rem;
        }
      }
    }
  }
}
