.data-grid-widget {
  .ui.grid > .row .column {
    label.required::after {
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin-left: 5px;
      background-color: #e40166;
      content: '';
    }
  }

  .ui.grid {
    margin: 0;

    .row > .column.term-actions {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-right: 0.5rem;
      padding-left: 0;

      .delete-term {
        margin-right: 0 !important;
      }
    }

    .row > .field-column {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }

    .row {
      .column.field-column {
        width: unset !important ;
        flex-basis: 45%;
        flex-grow: 0;

        .ui.input {
          width: 100% !important;
        }

        .date-time-widget-wrapper {
          position: relative;

          .item.ui.noborder.button {
            position: absolute;
            top: 26px;
            right: 12px;
          }

          .DateInput {
            width: 100% !important;

            input {
              width: 100% !important;
            }
          }
        }
        @media (max-width: 1365px) {
          max-width: 90%;
          flex-basis: 90%;
        }
      }

      .one.wide.column.term-actions {
        position: absolute;
        right: 0;
        @include media-breakpoint-down(md) {
          align-items: flex-end !important;
          padding-right: 0 !important;
        }
      }
    }
  }
}

// fix styles for tempi e scadenze timeline
.field-wrapper-timeline_tempi_scadenze .data-grid-widget {
  .row {
    .column.field-column {
      &:not(:nth-last-child(2)) {
        flex-grow: 0;
      }

      &:nth-last-child(2) {
        max-width: 90%;
        flex-basis: 90%;
      }

      .ui.input {
        width: 100% !important;
      }

      @media (max-width: 1365px) {
        flex-basis: 90%;

        &:not(:nth-last-child(2)) {
          flex-grow: unset;
        }
      }
    }
  }
}
