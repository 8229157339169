.select-icon-widget {
  .show-icon {
    font-size: 40px;
  }

  .show-icon-italia {
    height: 2rem;
  }

  .icon-container {
    width: 15px;
  }

  .label-container {
    width: 100%;
    margin-left: 15px;
    text-align: left;
  }

  .ms-4 {
    margin-left: 4px;
  }
}
