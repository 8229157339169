.sliderTemplate {
  .slider-container {
    .slick-slider {
      ul.slick-dots {
        position: unset;
      }
    }
  }

  @media (max-width: #{map-get($grid-breakpoints, md)}) {
    /*&.slidesToShow-4*/
    .slider-container {
      .slick-slide {
        .slide-wrapper {
          .img-wrapper {
            height: auto !important;
            img {
              height: auto;
              min-height: unset !important;
            }
          }
        }
      }
    }
  }
}
