.public-ui .form-check {
  input[type='checkbox'] {
    + label.indeterminate {
      &::before {
        top: 4px;
        left: 3px;
        width: 6px;
        border-bottom: 0;
        margin: 0;
        transform: rotate(90deg);
      }

      &::after {
        border-color: #bbb;
        background: #bbb;
      }
    }
  }
}
