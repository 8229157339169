body.is-authenticated {
  // se l'utente è autenticato e c'è la classe no-user-roles o role-citizen, allora si tratta di un 
  // utente del sito (cittadino) che accede solo all'area riservata
  &.no-user-roles, &.role-citizen {
    #toolbar,
    #sidebar {
      display: none;
    }

    &.has-toolbar,
    &.has-toolbar-collapsed {
      .full-width {
        left: 50%;
        width: 100vw !important;
      }
    }
  }
}
