.bando-view {
  #metadata {
    margin-bottom: 0.5em !important;

    #header-metadata {
      @extend .visually-hidden; //nascondi il titolo 'Ulteriori informazioni'
      // display: none;
    }
  }

  #update_note {
    color: $gray-secondary;
  }
}
