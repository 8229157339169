.list-template {
  .card {
    display: flex;
    flex-direction: row;

    h3.card-title {
      font-size: 1.3rem;
    }

    .card-text {
      display: flex;

      .date {
        color: var(--bs-gray-500);
      }

      .text.publication {
        .publication-row-infos {
          display: flex;
          gap: 1rem;
        }

        .pubblication_info {
          .publication_label {
            font-weight: bold;
          }
        }
      }
    }

    .card-image {
      min-width: 250px;
      max-width: 250px;
      figure {
        margin: 0;
      }
    }

    &.Pubblicazione {
      .card-image {
        min-width: 100px;
        max-width: 100px;
      }
    }
  }

  @media (max-width: #{map-get($grid-breakpoints, md)}) {
    .card {
      flex-direction: column;

      .card-image {
        min-width: 40%;
        max-width: 40%;
      }
    }
  }
}
