.simple-list-template ul,
.simple-list-skeleton-template ul {
  font-size: 1rem;

  li {
    a {
      text-decoration: underline;
    }
  }
}
