.public-ui {
  // text-justify was removed from bootstrap 5
  .text-justify {
    text-align: justify;
  }

  button.btn,
  button.rounded-right {
    &:focus {
      outline: 2px solid $outer-focus-outline !important;
      outline-offset: 2px;

      border: none !important;
      box-shadow: 0 0 0 2px $inner-focus-shadow !important;
    }
  }

  .btn-tertiary {
    @include button-variant($tertiary, $tertiary);

    svg {
      fill: $tertiary-text;
    }

    &:hover,
    &:active {
      svg {
        fill: $tertiary-text;
      }
    }
  }

  a.btn-tertiary {
    color: $tertiary-text !important;

    &:focus {
      border-color: $focus-outline-color;
      box-shadow:
        inset 0 1px 0 $focus-outline-color,
        0 1px 1px $focus-outline-color,
        0 0 0 0.2rem $focus-outline-color;
      outline: none;
    }
  }

  .btn-outline-tertiary {
    @include button-outline-variant($tertiary);
  }

  button.chip {
    &:focus {
      outline: none;
    }
  }

  .btn-primary {
    &,
    &:hover,
    &:active {
      svg.icon {
        color: $primary-text;
        fill: $primary-text;
      }
    }
  }

  .external-link {
    fill: currentColor;

    &.align-sub {
      vertical-align: sub;
    }
  }
}

.icon.external-link.icon-xs {
  height: 1rem !important;
}

section.it-page-section,
.last-modified,
.it-page-subsection {
  clear: both;
}
