.block .complete-block-links-template {
  .external-source {
    color: #fff;
  }

  a {
    &,
    &:hover,
    &:active,
    &:focus,
    &:focus-within {
      text-decoration: none;
    }
  }
}
