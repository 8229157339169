.block.contacts {
  .contact-info {
    a {
      width: 100%;
    }
  }
  @media (min-width: #{map-get($grid-breakpoints, lg)}) {
    /*se c'è solo un elemento lo mostro a tutta larghezza*/
    .row div:nth-child(1 of .col-lg-4):nth-last-child(1 of .col-lg-4) {
      width: 100% !important;
    }
  }
}
