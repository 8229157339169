.card-wrapper {
  &.card-teaser-wrapper {
    .card-teaser {
      .card-body {
        max-width: 100%;
      }

      .icon {
        //to do: forse da spostare nel file degli attachment
        min-width: 32px;
        max-width: 32px;

        & + .card-body {
          max-width: calc(100% - 1em - 32px);

          .card-title a {
            max-width: 100%;
          }
        }
      }
    }
    //Landscape
    @media (min-width: #{map-get($grid-breakpoints, md)}) {
      .card-teaser {
        flex: 0 0 49%;
      }

      &.card-teaser-block-3 {
        width: 100%;
        justify-content: space-between;

        & > .card-teaser {
          flex: 0 0 32%;
        }

        &:after {
          flex: 0 0 32%;
          content: '';
        }
      }

      &.card-teaser-block-4 {
        width: 100%;
        justify-content: space-between;

        & > .card-teaser {
          flex: 0 0 24%;
        }

        &:after {
          flex: 0 0 24%;
          content: '';
        }
      }
    }
  }
}

.card {
  .categoryicon-top {
    .icon {
      color: $gray-secondary;
      fill: $gray-secondary;
    }
  }
}

a.read-more {
  color: $link-color;
}
