.block.simple-text-block {
  .card {
    .card-body {
      .card-title {
        .simple-text-editor-widget {
          font-weight: 600;
        }
      }
      .card-text {
        .slate-editor * {
          font-family: 'Lora, Georgia, serif';
          line-height: 1.5em;
          font-size: 1.1rem;
        }
      }
    }
  }
}
