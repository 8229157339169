.cmp-timeline {
  margin-top: 1.5rem;

  @include media-breakpoint-up(sm) {
    margin-top: 1.33rem;
  }

  @include media-breakpoint-up(lg) {
    margin-top: 0;
  }

  .info-text {
    // @include font(16, 18);
    @include media-breakpoint-down(lg) {
      font-size: rfs-fluid-value(1rem);
    }
    color: get-color(light, grey);
    font-weight: 600;
    line-height: 1.5;

    @include media-breakpoint-up(md) {
      line-height: 1.56;
    }
  }
}

.calendar-vertical {
  clear: both;
  color: $link-color;

  &::after {
    display: block;
    clear: both;
    content: '';
  }

  .title-xxlarge-regular {
    font-size: 2.222222rem;
    @include media-breakpoint-down(md) {
      font-size: rfs-fluid-value(2.222222rem);
    }
    font-weight: 400;
    line-height: 1;
  }

  .title-medium-2 {
    font-size: 1.5rem;
    @include media-breakpoint-down(md) {
      font-size: rfs-fluid-value(1.5rem);
    }
    font-weight: 400;
    line-height: 1.2;
  }
}

.calendar-vertical .calendar-date {
  display: flex;

  & .calendar-date-description {
    overflow: hidden;
    width: 100%;
    margin: 0 0 1.5rem 0.813rem;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.17);

    @include media-breakpoint-up(sm) {
      margin: 0 0 1.33rem 0.72rem;
    }

    & .calendar-date-description-content {
      position: relative;
      z-index: 0;
      padding: 1.25rem 1rem 1.25rem 1.5rem;
      border-left: 8px solid $primary-a0;

      @include media-breakpoint-up(sm) {
        padding: 1.11rem 0.89rem 1.11rem 1.33rem;
      }

      // &:before {
      //   content: '';
      //   width: 0.5rem;
      //   height: 120%;
      //   position: absolute;
      //   z-index: 1;
      //   top: 0;
      //   left: 0;
      //   background-color: #f0f8f5;

      //   @include media-breakpoint-up(sm) {
      //     width: 0.44rem;
      //   }
      // }

      & span {
        margin: 0;
      }
    }
  }

  & .calendar-date-day {
    position: relative;
    z-index: 0;
    width: 4.06rem;
    flex-shrink: 0;
    padding: 0.5rem 0;
    padding-right: 1rem;

    & p {
      display: block;
      margin: 0 0 5px 0;
      font-family: 'Roboto Mono', monospace !important;
      font-size: 2.5rem;
      line-height: 1;
    }

    & small {
      font-size: rfs-fluid-value(rfs-value(14px));
      line-height: 1;
    }

    &:before {
      position: absolute;
      z-index: 1;
      top: 0;
      right: 5px;
      width: 2px;
      height: 100%;
      background-color: $link-color;
      content: '';
    }

    &:after {
      position: absolute;
      z-index: 1;
      top: 1.875rem;
      right: 0.2px;
      width: 0.625rem;
      height: 0.625rem;
      border-radius: 5px;
      background-color: $link-color;
      content: '';

      @include media-breakpoint-down(md) {
        top: 1.56rem;
        width: 0.56rem;
        height: 0.56rem;
      }
    }

    &__month {
      display: flex;
      justify-content: center;
      font-weight: 600;
    }

    &__year {
      display: flex;
      justify-content: center;
      font-weight: 400;
    }
  }

  @include media-breakpoint-down(md) {
    .calendar-vertical .calendar-date .calendar-date-day span {
      font-size: 2rem;
      font-weight: 100 !important;
    }
  }

  .calendar-vertical .calendar-date .calendar-date-day small {
    font-size: rfs-fluid-value(1rem);
  }
}

.calendar-date:last-child {
  .calendar-date-description {
    margin-bottom: 0;
  }
}
