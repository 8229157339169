.pagination-wrapper {
  @include media-breakpoint-down(xl) {
    ul.pagination {
      flex-wrap: nowrap;
    }

    li.page-item {
      button.page-link {
        min-width: 1rem;
        height: 2rem;
      }

      &:first-child,
      &:last-child {
        button.page-link {
          padding: 0px;
        }
      }
    }
  }
}

.pagination-wrapper {
  li.page-item {
    button.page-link {
      .icon.icon-primary.disabled {
        fill: $gray-600 !important;
      }
    }
  }
}
