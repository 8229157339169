.page-side-menu {
  .navbar.it-navscroll-wrapper {
    .link-list-wrapper.menu-link-list {
      h3 {
        @include rem-size(font-size, 14);
        @include rem-size(line-height, 24);
        color: $body-color;
      }

      ul {
        li {
          @include rem-size(font-size, 18);
          @include rem-size(line-height, 28);

          a {
            span {
              // @include rem-size(font-size, 18);
              font-weight: 400;
              text-decoration: none;
            }
          }

          a.nav-link.active {
            span {
              font-weight: 700;
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}
