body.cms-ui .block.audio {
  h2,
  audio,
  .transcript {
    margin-bottom: 1rem;
  }

  .transcript > p {
    margin-bottom: 0;
  }
}
