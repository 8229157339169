.photogallery {
  figure.img-wrapper {
    figcaption {
      display: none;
    }

    @media (max-width: #{map-get($grid-breakpoints, md)}) {
      &.volto-image,
      picture.volto-image {
        &.responsive img {
          height: auto;
        }
      }
    }
  }
}
