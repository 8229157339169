.subsite-footer {
  background-color: $primary;

  .text {
    padding: 3.5em 0;
    color: $primary-text;

    h1,
    h2,
    h3,
    h4,
    a {
      color: $primary-text;
    }

    p {
      margin: 0;
    }
  }
}
