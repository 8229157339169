#metadata {
  h6 {
    margin-bottom: 1em;
  }

  .callout {
    margin-bottom: 2em;
  }

  &.bottom-metadata {
    margin: 1rem 0;
    font-size: 0.8rem;
    clear: both;
  }
}
