//mobile

//search field
.autocomplete {
  padding: 0.375rem $v-gap * 6 0.375rem $v-gap * 3;

  &::placeholder {
    color: $color-text-muted; // UI kit
  }

  &:disabled {
    background-color: #cacacc;

    &::placeholder {
      color: $gray-secondary;
    }
  }
}

//search icon
.autocomplete-icon {
  position: absolute;
  right: 0;
  top: 0.5rem;
  padding: 0 $spacer * 0.5;
  background-color: $white;
  .icon {
    fill: $gray-secondary;
  }
}

//results list
.autocomplete-list {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  background-color: $white;
  padding: $v-gap 0;
  box-shadow: $dropdown-box-shadow;
  border-top: 1px solid $gray-secondary;
  transition: opacity 0.3s;
  display: none;

  &.autocomplete-list-show {
    display: block;
    z-index: 10;
  }

  li {
    list-style-type: none;
    padding: 0;
  }

  //single item
  a {
    padding: 12px $v-gap * 3;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    color: $gray-primary;
    line-height: 1.2;
    font-size: 1rem;
    text-decoration: none;
    //hover state
    &:hover {
      color: $primary;
      text-decoration: none;

      .icon {
        fill: $primary;
      }

      .autocomplete-list-text {
        //item text
        span {
          text-decoration: underline;
        }

        //item label
        em {
          color: $primary;
        }
      }

      //search highlight
      mark {
        color: $primary;
      }
    }

    //item icon + avatar
    .icon,
    .avatar {
      margin-right: $v-gap * 2;
      flex-shrink: 0;
    }

    .icon {
      fill: $gray-primary;
    }

    //item text
    .autocomplete-list-text {
      margin-top: 2px;
      //item label
      em {
        font-style: normal;
        text-transform: uppercase;
        font-size: 0.75rem;
        color: $color-text-secondary; // UI kit
        font-weight: 600;
        letter-spacing: 0.1em;
        display: block;
        margin-top: $v-gap * 0.5;
      }
    }
  }

  //search highlight
  mark {
    padding: 0;
    background: transparent;
    font-weight: bold;
  }
}

//big version
.autocomplete-wrapper-big {
  //search field
  .autocomplete {
    height: $v-gap * 7;
    font-size: 1.5rem;
    font-weight: normal;
    padding-left: $spacer * 4;
    padding-right: $v-gap * 3;
  }

  //search icon
  .autocomplete-icon {
    position: absolute;
    left: 0.5rem;
    right: auto;
    top: 1rem;

    .icon {
      fill: $primary;
      width: 24px;
      height: 24px;
    }
  }

  //results list
  .autocomplete-list {
    a {
      font-size: 1.125rem;

      .autocomplete-list-text {
        margin-top: 0;
      }
    }
  }
}

//small - tablet
@include media-breakpoint-up(sm) {
  //results list
  .autocomplete-list {
    a {
      font-size: 0.889rem;
      align-items: flex-start;

      .autocomplete-list-text {
        //item text
        span {
          margin-right: $v-gap;
        }

        //item label
        em {
          display: inline-block;
          font-size: 0.667rem;
          margin-top: 0;
        }
      }
    }
  }

  //big version
  .autocomplete-wrapper-big {
    //search field
    .autocomplete {
      height: $v-gap * 9;
      font-size: 2.222rem;
      padding-left: $v-gap * 8;
    }

    //search icon
    .autocomplete-icon {
      .icon {
        width: 40px;
        height: 40px;
      }
    }

    //results list
    .autocomplete-list {
      a {
        font-size: 1.111rem;
        //item text
        .autocomplete-list-text {
          span {
            margin-right: $v-gap * 2;
          }
        }
      }
    }
  }
}
