@media (min-width: #{map-get($grid-breakpoints, lg)}) {
  #page-document {
    .PageHeaderWrapper {
      .title-description-wrapper {
        &.col-lg-6 {
          width: 66.66666667%;
        }
        + .col-lg-4.offset-lg-2 {
          margin-left: 0;
        }
      }
    }
  }
}
